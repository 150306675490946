import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaSfAlg.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(ALG_(INPUT|OUTPUT|SUMMARY))$/.test(type)
        },

        onPush(map, node, type, data) {
            if ('SF' !== type) {
                return
            }

            const tree = this.exportTree(map, node, {excludeGrown: true})
            const algMap = JSON.stringify(tree.children)
            data.algList.push({...tree.data, algMap})
        },

        isHidden(map, node) {
            return /^(SF)_LVL$/.test(map.data.project?.desgAcc)
        },
    })
}
