import extendNode from '../extendNode.mjs'
import buildUrl from '@/script/buildUrl.mjs'
import {toProductMap} from '../../compatibility.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import apiSnapshot from '../../apis/apiSnapshot.mjs'
import useDesignNode from '../_DESIGN/useDesignNode.jsx'
import meta from './metaProductNode.mjs'

/**
 * 制品节点类型的基类
 */
export default () => {
    const DesignNode = useDesignNode()

    return extendNode(DesignNode, {
        ...meta,
        isProduct: true,

        get create() {
            const {create} = this.api

            if (! create) {
                return void 0
            }

            return async (map, node) => {
                const data = this.getCreateData(map, node)
                const {prjId, sVer} = map.data

                const {
                    [this.mapProp]: _,
                    ...updates
                } = await create({...data, prjId, sVer})

                return {
                    ...updates,
                    stsCode: 'DRAFT',
                    stsName: '草稿',
                }
            }
        },

        get discard() {
            const {discard} = this.api

            if (! discard) {
                return void 0
            }

            return async (map, node) => {
                const {
                    [this.mapProp]: _,
                    ...updates
                } = await discard(node.data)

                return updates
            }
        },

        get publish() {
            const {publish} = this.api

            if (! publish) {
                return void 0
            }

            return async (map, node, extra) => {
                const {pkid} = node.data
                const result = await publish({...extra, pkid})
                const {dppChangeList, ...updates} = this.clean(result)
                node.data = {...node.data, ...updates}

                if (! dppChangeList) {
                    return
                }
                const componentUpdates = new Map(
                    dppChangeList.map(({pkid, rev}) => [pkid, {rev}])
                )

                const next = chain => {
                    const [node] = chain
                    const {pkid} = node.data
                    const yieldNode = componentUpdates.has(pkid)
                    return {yieldNode}
                }

                for (
                    const n of
                    map.walkDown(node, {excludeTarget: true, next})
                ) {
                    const {rev} = componentUpdates.get(n.data.pkid)
                    const _n = map.nodeProxy(n)

                    if (_n.isMounted()) {
                        n.data = {...n.data, lastRev: rev, rev}
                    }
                    else {
                        n.data = {...n.data, lastRev: rev}
                    }
                }
            }
        },

        get pull() {
            const {pull} = this.api

            if (! pull) {
                return void 0
            }

            return async (map, node) => {
                const {
                    [this.mapProp]: _,
                    ...tree
                } = await pull(node.data)

                await this.replace(map, node, parseMap(tree))
                await this.onPull(map, node)
            }
        },

        get push() {
            const {push} = this.api

            if (! push) {
                return void 0
            }

            return async (map, node) => {
                const args = this.getPushData(map, node)
                const result = await push(args)
                const data = this.clean(JSON.parse(result))
                const updates = this.mapPushResult(data)

                await Promise.all(
                    [...updates.entries()].map(
                        async ([id, updates]) => {
                            const n = map.getNode(id)
                            const {bizNodeType: t} = n.data

                            n.data = {
                                ...n.data,
                                ...map.BizNode[t].clean(updates)
                            }

                            await map.BizNode[t].onPushDone(map, n)
                        }
                    )
                )
            }
        },

        get read() {
            const {read} = this.api

            if (! read) {
                return void 0
            }

            return async args => {
                const {
                    [this.mapProp]: _,
                    ...node
                } = await read(args)

                return {...node, bizNodeType: this.type}
            }
        },

        get readTree() {
            const {read} = this.api

            if (! read) {
                return void 0
            }

            return async args => {
                if (args.rev) {
                    const snapshot = await apiSnapshot.readByRev(
                        {...args, bizNodeType: this.type}
                    )

                    const {root} = parseMap(snapshot)
                    const {prjNo} = snapshot.data
                    Object.assign(root.data, {prjNo})
                    return root
                }
                else {
                    const {
                        [this.mapProp]: mapJson,
                        ...data
                    } = await read(args)

                    const tree = {
                        data: {
                            ...data,
                            bizNodeType: this.type,
                        },

                        children: [],
                    }

                    if (mapJson) {
                        const {root: {children}} = parseMap(mapJson)
                        Object.assign(tree, {children})
                    }

                    return tree
                }
            }
        },

        get readMap() {
            const {read} = this.api

            if (! (read && this.mapProp)) {
                return void 0
            }

            return async args => {
                const {
                    [this.mapProp]: mapJson,
                    ...rootData
                } = await read(args)

                const mapData = parseMap(mapJson)
                mapData.data.mapRev = rootData.rev
                Object.assign(mapData.root.data, rootData)
                return mapData
            }
        },

        get readHierarchyMap() {
            const {readHierarchyMap} = this.api

            if (! readHierarchyMap) {
                return void 0
            }

            return async args => {
                const mapJson = await readHierarchyMap(args)
                return parseMap(mapJson)
            }
        },

        get revise() {
            const {revise} = this.api

            if (! revise) {
                return void 0
            }

            return async (map, node) => {
                const {
                    [this.mapProp]: _,
                    ...updates
                } = await revise(node.data)

                return updates
            }
        },

        get update() {
            const {update} = this.api

            if (! update) {
                return void 0
            }

            return async (map, node) => {
                const {
                    [this.mapProp]: _,
                    ...updates
                } = await update(node.data)

                return updates
            }
        },

        get updateHierarchy() {
            const {updateHierarchy} = this.api

            if (! updateHierarchy) {
                return void 0
            }

            return async (map, node) => {
                const tree = this.exportTree(map, node)
                await updateHierarchy(tree)
            }
        },

        get updateMap() {
            const {update, updateMap} = this.api
            const doUpdate = updateMap ?? update

            if (! doUpdate) {
                return void 0
            }

            return async (map, node) => {
                const mapName = this.getText(map, node)
                const root = this.exportTree(map, node, {excludeGrown: true})

                const mapData = {
                    data: {...map.data, mapName},
                    root,
                }

                const json = JSON.stringify(toProductMap(mapData))

                const {
                    [this.mapProp]: _,
                    ...updates
                } = await doUpdate({
                    ...node.data,
                    mapSize: json.length,
                    nodeQty: node.descendantCount + 1,
                    [this.mapProp]: json,
                })

                return updates
            }
        },

        get updateMigrate() {
            const {updateMigrate} = this.api

            if (! updateMigrate) {
                return void 0
            }

            return async (map, node) => {
                const mapName = this.getText(map, node)
                const root = this.exportTree(map, node)

                const mapData = {
                    data: {...map.data, mapName},
                    root,
                }

                const migrateMap = JSON.stringify(toProductMap(mapData))

                const {
                    [this.mapProp]: _1,
                    migrateMap: _2,
                    ...updates
                } = await updateMigrate({
                    ...node.data,
                    mapSize: migrateMap.length,
                    migrateMap,
                    nodeQty: node.descendantCount + 1,
                })

                return updates
            }
        },

        canGrow(map, node) {
            if (! DesignNode.canGrow.call(this, map, node)) {
                return false
            }

            if (! this.readTree) {
                map.logger.error(`${this.name}不支持加载/卸载`, [node])
                return false
            }

            return true
        },

        canWriteData(map, node) {
            return (
                (
                    ! node.data.pkid ||
                    node === map.root
                ) &&

                DesignNode.canWriteData.call(this, map, node)
            )
        },

        canWriteTree(map, node) {
            return (
                node === map.root &&
                DesignNode.canWriteTree.call(this, map, node)
            )
        },

        getCreateData(map, node) {
            this._validate(node)
            return {id: node.id, ...node.data}
        },

        getUrl(map, node, childPath = []) {
            const {
                [this.textProp]: text = '',
                pkid,
            } = node.data

            if (pkid) {
                const q = this._getUrlQuery(node)

                if (0 < childPath.length) {
                    const path = [encodeURIComponent(text), ...childPath]

                    q.mapInits = {
                        selectedNodes: [{
                            path: `/${path.join('/')}/`,
                        }],
                    }
                }

                return buildUrl(this.detailUrl, q)
            }
            else {
                return DesignNode.getUrl.call(this, map, node)
            }
        },

        jumpToDetail(map, node) {
            const {pkid} = node.data

            const url = buildUrl(
                this.detailUrl, {[this.detailIdProp]: pkid}
            )

            window.open(url)
        },

        jumpToDiff(map, node, leftRev, rightRev) {
            const {bizNodeType, pkid} = node.data
            const text = this.getTextRaw(map, node)

            const url = buildUrl(
                '/DiffMap',

                {
                    id: pkid,
                    leftRev,
                    rightRev,
                    title: `${this.name}【${text}】`,
                    type: bizNodeType,
                }
            )

            window.open(url)
        },

        mapPushResult(data) {
            return this._mapPushResult(data)
        },

        menuInsertConcept(map, nodes) {
            return this._menuInsertConcept(map, nodes)
        },

        async onAttachTo(map, node) {
            if (this.isLinked(map, node)) {
                if (
                    // 没有 rev 字段视为版本 0
                    ! node.data.rev &&
                    // 在自己的制品图里允许粘贴
                    map.root.data.pkid !== node.data.pkid
                ) {
                    map.deleteTree(node)
                    map.logger.error('不能粘贴版本为0的模件', [node])
                    return
                }
            }

            await DesignNode.onAttachTo.call(this, map, node)
        },

        async onDoubleClick(map, node, event) {
            const {
                deliverRev,
                deliverVer,
                pkid,
                snapshotId,
                sVer,
            } = node.data

            if (pkid) {
                if (node.parent) {
                    event.preventDefault()
                    const rev = this.getRev(map, node)

                    if (
                        deliverRev &&
                        deliverVer &&
                        '_DES_MAP' === map.data.mapTypeCode &&

                        (
                            deliverRev !== rev ||
                            deliverVer !== sVer
                        )
                    ) {
                        this.jumpToDiff(map, node, rev, deliverRev)
                    }
                    else if (
                        this.isOutdated(map, node) &&
                        0 < rev
                    ) {
                        this.jumpToDiff(map, node, rev)
                    }
                    else {
                        this.jumpToDetail(map, node)
                    }
                }
                else if (snapshotId) {
                    const url = buildUrl(
                        '/SoftwareMap', {id: snapshotId, mode: 'snapshot'}
                    )

                    window.open(url)
                }
                else if (
                    '' === snapshotId ||
                    /^(ANALYSE|DEPEND|EFFECT)_MAP$/.test(map.data.mapTypeCode)
                ) {
                    this.jumpToDetail(map, node)
                }
                else {
                    DesignNode.onDoubleClick.call(this, map, node, event)
                }
            }
            else {
                DesignNode.onDoubleClick.call(this, map, node, event)
            }
        },

        async upgrade(map, node) {
            const {deliverRev, deliverVer, pkid} = node.data
            const tree = await this.readTree({pkid})
            const {rev, stsCode} = tree.data

            if (! /^(RLS|REVISE)$/.test(stsCode)) {
                throw new Error('制品不是发布/修订状态，不能升级')
            }

            node.data = {
                ...tree.data,
                deliverRev,
                deliverVer,
                lastRev: rev,
                rev
            }
        },

        _getUrlQuery(node) {
            return {[this.detailIdProp]: node.data.pkid}
        },

        async _readGrowTree(map, node) {
            const {pkid} = node.data
            const rev = this.getRev(map, node)
            return this.readTree({pkid, rev})
        },

        _mapPushResult(result, listNames = []) {
            const id2updates = new Map()

            const collect = (items) => {
                for (const data of items) {
                    const {child = [], id, ...updates} = data

                    if (id) {
                        id2updates.set(id, updates)
                        collect(child)
                    }
                }
            }

            for (const listName of listNames) {
                collect(result[listName])
            }

            return id2updates
        },
    })
}
