import extendNode from '../extendNode.mjs'
import http from '@/biz/http.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import Color from '../Color.mjs'
import IconLetters from '../icons/IconLetters.jsx'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import meta from './metaBdevent.mjs'
import Model from './ModelBdevent.mjs'
import PropertiesPane from './PropertiesPaneBdevent.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        attrNodes: {
            top: ['ATTR_BDEVENT_MEMO'],
        },

        canMountType(map, node, type) {
            return /^EVENT_(PUBLISHER|SUBSCRIBER)$/.test(type)
        },

        getIcons(map, node) {
            return [
                <IconLetters
                    key="type"
                    fill={Color.LIGHT_YELLOW}
                    letters="E"
                    textColor="#000"
                />
            ]
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.LIGHT_YELLOW,
                shape: 'EllipseRectangle',
            })
        },

        async onCreate(map, node) {
            const childTypes = [
                'EVENT_PUBLISHER',
                'EVENT_SUBSCRIBER',
                'EVENT_DESC',
            ]

            for (const t of childTypes) {
                const n = map.createNode(t)
                map.appendChild(node, n)
            }

            await ComponentNode.onCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            const key = {
                BD: 'eventList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(map, node)
            data[key].push(pushData)
        },

        _getPushData(map, node, slots) {
            const data = ComponentNode._getPushData.call(this, map, node, slots)
            const n = map.nodeProxy(node)
            const tree = n.exportTree(map, node, {excludeGrown: true})
            const eventMap = JSON.stringify(tree.children)
            return {...data, eventMap}
        },

        async _readGrowTree(map, node) {
            const readSelf = async () => {
                const tree = await ComponentNode._readGrowTree.call(
                    this, map, node
                )

                return {
                    ...tree,

                    children: tree.children.filter(
                        n => {
                            const {bizNodeType} = n.data

                            return (
                                'EVENT_PUBLISHER' !== bizNodeType &&
                                'EVENT_SUBSCRIBER' !== bizNodeType
                            )
                        }
                    )
                }
            }

            const readEvents = async () => {
                const {prjId, rev, eventCode, sVer} = node.data

                const result = await http.get(
                    '/dmbds/event/extend',
                    {prjId, rev, eventCode, sVer}
                )

                return result &&
                    JSON.parse(result).map(parseMap)
            }

            const [tree, events] = await Promise.all([
                readSelf(),
                readEvents(),
            ])

            if (events) {
                for (const n of events.toReversed()) {
                    tree.children.unshift(n)
                }
            }

            return tree
        },
    })
}
