import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaFcJob.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(CAT|MARK|FJ|PS)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'FJ'
        },

        onPush(map, node, type, data) {
            if ('FC' !== type) {
                return
            }

            CategoryNode.onPush.call(this, map, node, type, data)
            const {bizNodeType} = node.data

            const tree = map.BizNode[bizNodeType].exportTree(
                map, node, {excludeGrown: true}
            )

            data.jobMap = JSON.stringify(tree.children)
        },
    })
}
