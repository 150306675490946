import _Menu from '../../Menu.jsx'
import MenuButtonEdit from '../../menuButtons/MenuButtonEdit.jsx'
import MenuButtonInsertNode from '../../menuButtons/MenuButtonInsertNode.jsx'
import MenuButtonInsertProduct from '../../menuButtons/MenuButtonInsertProduct.jsx'
import MenuButtonInsertProducts from '../../menuButtons/MenuButtonInsertProducts/MenuButtonInsertProducts.jsx'
import MenuButtonMap from './MenuButtonMap.jsx'
import MenuButtonPublishProduct from './MenuButtonPublishProduct.jsx'
import MenuButtonPullProduct from './MenuButtonPullProduct.jsx'
import MenuButtonPushProduct from './MenuButtonPushProduct.jsx'
import MenuButtonRefreshProduct from './MenuButtonRefreshProduct.jsx'
import MenuButtonReviseProduct from './MenuButtonReviseProduct.jsx'

export default function Menu(props) {
    return (
        <_Menu {...props}>
            <MenuButtonMap />
            <MenuButtonEdit />
            <MenuButtonInsertNode type="common" />
            <MenuButtonInsertNode type="concept" />
            <MenuButtonInsertProduct />
            <MenuButtonInsertProducts />
            <MenuButtonPushProduct />
            <MenuButtonPullProduct />
            <MenuButtonRefreshProduct />
            <MenuButtonPublishProduct />
            <MenuButtonReviseProduct />
        </_Menu>
    )
}
