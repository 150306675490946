import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import DtOprType from './DtOprType.mjs'
import FjType from './FjType.mjs'
import Model from './ModelFj.mjs'

const Controllers = () => {
    const [values] = useProperties()

    return (
        <>
            <LongText prop="fjTitle" />

            <Select
                options={FjType}
                prop="fjTypeCode"
            />

            {
                {
                    DT: (
                        <>
                            <Select
                                options={DtOprType}
                                prop="dtOprCode"
                            />

                            <Text prop="txNo" />
                        </>
                    ),

                    LSI: <Text prop="txNo" />,
                }[values.fjTypeCode]
            }

            <LongText prop="fjDesc" />
            <LongText prop="memo" />
        </>
    )
}

const PropertiesPaneFj = (props) => (
    <ComponentPropertiesPane
        controllers={<Controllers />}
        Model={Model}
        noSn
        snSuffix={<Analyse modes={['Reference']} />}
        {...props}
    />
)

export default PropertiesPaneFj
