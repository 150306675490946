import {useMapContext} from '@/components/MindMap/index.mjs'
import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import ButtonMap from '../components/PropertiesPaneButtonMap.jsx'
import ButtonExportModel from '../components/PropertiesPaneButtonExportModel.jsx'
import Model from './ModelBm.mjs'

const PropertiesPaneBm = (props) => {
    const map = useMapContext()
    const [node] = map.useSelectedNodes()

    return (
        <ComponentPropertiesPane
            buttons={
                (
                    'SA_MAP' !== map.data.mapTypeCode ||

                    (() => {
                        for (const n of map.walkUpNoComment(node.parent)) {
                            const {bizNodeType} = n.data

                            if (bizNodeType !== node.data.bizNodeType) {
                                return 'CAT_DATA' !== bizNodeType
                            }
                        }

                        return true
                    })()
                ) &&

                <>
                    <ButtonMap />
                    <ButtonExportModel type="bm" />
                </>
            }

            controllers={
                <>
                    <Text prop="bmNo" />
                    <Text prop="bmName" />
                </>
            }

            Model={Model}
            snSuffix={<Analyse modes={['Reference']} />}
            {...props}
        />
    )
}

export default PropertiesPaneBm
