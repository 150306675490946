import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'
import SelectDm from '../DM/SelectDm.jsx'

const FormItemDms = (props) => {
    return (
        <Form.Item
            label="库表域"
            name="dms"
            {...props}
        >
            <SelectDm multiple />
        </Form.Item>
    )
}

const FormItemCdgCode = (props) => {
    return (
        <Form.Item
            label="字段组代码"
            name="pcdgUserCode"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemCdgName = (props) => {
    return (
        <Form.Item
            label="字段组名称"
            name="pcdgName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const QueryFormSelectCdg = (props) => {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemDms />
            </Col>

            <Col span={6}>
                <FormItemCdgCode />
            </Col>

            <Col span={6}>
                <FormItemCdgName />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}

export default QueryFormSelectCdg
