import extendNode from '../extendNode.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaUi.mjs'
import Model from './ModelUi.mjs'
import PropertiesPane from './PropertiesPaneUi.jsx'
import QueryForm from './QueryFormSelectUi.jsx'
import Table from './TableSelectUi.jsx'
import api from './apiUi.mjs'
import useContextMenu from './useContextMenuUi.mjs'

export default () => {
    const ProductNode = useProductNode()
    const contextMenu = useContextMenu()

    return extendNode(ProductNode, {
        ...meta,
        api,
        contextMenu,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(UI_(CALG|DC|DEMO|PARAM|RULE|UE))$/.test(type)
        },

        async chooseProduct(map, node) {
            const getQuery = (map, node, {fms = [], ss, ...query}) => ({
                ...query,
                fmIds: fms.map(({fmId}) => fmId),
                ssId: ss?.ssId,
            })

            return this._chooseProduct(
                map, node, QueryForm, Table, {getQuery}
            )
        },

        clean(nodeData) {
            const {
                migrateMap,
                ...data
            } = ProductNode.clean.call(this, nodeData)

            return data
        },

        getIcons(map, node) {
            if (node.data.pkid) {
                return []
            }
            else {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.BLUE}
                        letters="U"
                        textColor="#fff"
                    />
                ]
            }
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {
                cAlgList: [],
                demoList: [],
                inputAlgList: [],
                outputAlgList: [],
                ruleList: [],
                umUeList: [],
                uvList: [],
                varAlgList: [],
            })
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.LIGHT_BLUE,
                }),

                shape: 'Rectangle',
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(data, [
                'cAlgList',
                'demoList',
                'inputAlgList',
                'outputAlgList',
                'ruleList',
                'umUeList',
                'uvList',
                'varAlgList',
            ])
        },

        async onAttachTo(map, node) {
            await ProductNode.onAttachTo.call(this, map, node)
            await this.onInsert(map, node)
        },

        async onCreate(map, node) {
            for (const n of map.walkUp(node.parent)) {
                const {
                    bizNodeType,
                    fmCode,
                    fmId,
                    fmName,
                    ssCode,
                    ssId,
                    ssName,
                } = n.data

                if (/^CAT_ROOT_[FU]M$/.test(bizNodeType)) {
                    node.data = {
                        ...node.data,
                        fmCode,
                        fmId,
                        fmName,
                        ssCode,
                        ssId,
                        ssName,
                    }

                    break
                }
            }

            await ProductNode.onCreate.call(this, map, node)
        },

        async onInsert(map, node) {
            for (const n of map.walkUpNoComment(node.parent)) {
                const {bizNodeType} = n.data

                if ('AR' === bizNodeType) {
                    const {pkid} = node.data
                    const treeData = await this.readTree({pkid})
                    const ui = map.importTree(treeData)

                    const uiInput = (() => {
                        for (const n of ui.children) {
                            if ('UI_INPUT' === n.data.bizNodeType) {
                                return n
                            }
                        }
                    })()

                    if (! uiInput) {
                        map.deleteTree(ui)
                        break
                    }

                    const addAr = node.nextSibling ?
                        ar => map.insertSiblingBefore(node.nextSibling, ar) :
                        ar => map.appendChild(node.parent, ar)

                    const next = (chain) => {
                        const {bizNodeType: t} = chain[0].data
                        const yieldNode = 'AR' === t

                        const yieldChildren = (
                            'AR' === t ||
                            map.BizNode[t].isCategory
                        )

                        return {yieldChildren, yieldNode}
                    }

                    for (
                        const n of
                        map.walkDown(uiInput, {excludeTarget: true, next})
                    ) {
                        const {
                            arTypeCode,
                            [map.BizNode.AR.textProp]: t,
                        } = n.data

                        if ('VAR' !== arTypeCode) {
                            continue
                        }

                        const text = t.replace(
                            /^(?:<IP>)? *(\$*.*)$/,
                            '<IP> $1'
                        )

                        const data = {
                            ...n.data,
                            arTypeCode: 'SET_IP',
                            [map.BizNode.AR.textProp]: text,
                        }

                        const ar = map.importTree({data})
                        addAr(ar)
                    }

                    map.deleteTree(ui)
                }

                break
            }

            await ProductNode.onInsert.call(this, map, node)
        },

        async _grow(map, node, depth) {
            const newDepth = await ProductNode._grow.call(
                this, map, node, depth
            )

            if (newDepth === depth) {
                return depth
            }

            const {isMigrate, mapTypeCode} = map.data

            if (
                1 !== isMigrate &&
                ! /^(BDM|UI)_MAP$/.test(mapTypeCode)
            ) {
                for (const n of map.walkUpNoComment(node.parent)) {
                    const {bizNodeType} = n.data

                    if ('FRES' !== bizNodeType) {
                        break
                    }

                    const next = chain => {
                        const _n = map.nodeProxy(chain[0])
                        const isMounted = _n.isMounted()

                        return {
                            yieldChildren: isMounted,
                            yieldNode: isMounted,
                        }
                    }

                    for (
                        const n of
                        map.walkDown(node, {excludeTarget: true, next})
                    ) {
                        const {bizNodeType, ueTypeCode} = n.data

                        if (
                            'UE' === bizNodeType &&
                            'INI' === ueTypeCode
                        ) {
                            map.unlinkTree(n)
                            map.prependChild(node, n)

                            while (n.nextSibling) {
                                map.deleteTree(n.nextSibling)
                            }
                        }
                    }
                }
            }

            return newDepth
        },

        async _readGrowTree(map, node) {
            const {isMigrate, mapTypeCode} = map.data

            if (
                1 === isMigrate ||
                /^(BDM|UI)_MAP$/.test(mapTypeCode)
            ) {
                const {pkid} = node.data
                const {migrateMap} = await api.readMigrateMap({pkid})
                const {root} = parseMap(migrateMap)
                return root
            }
            else {
                return ProductNode._readGrowTree.call(this, map, node)
            }
        },
    })
}
