import {useMapContext} from '@/components/MindMap/index.mjs'
import MenuButton from '../../MenuButton.jsx'

export default function MenuButtonRefreshProduct(props) {
    const map = useMapContext()
    map.usePermission()

    if (/^_(DT)_MAP$/.test(map.data.mapTypeCode)) {
        return null
    }

    const {canRefreshProduct, refreshProduct} = map.commands
    const nodes = canRefreshProduct([map.root])

    if (0 === nodes.length) {
        return null
    }

    const handleClick = () => refreshProduct(nodes)

    return (
        <MenuButton
            onClick={handleClick}
            {...props}
        >
            重载制品
        </MenuButton>
    )
}
