import {css} from '@emotion/react'
import {useMapContext} from '@/components/MindMap/index.mjs'
import NodeExtraText from './NodeExtraText.jsx'

const cssText = css({
    color: 'red !important',
})

export default function NodeProject({node, ...props}) {
    const map = useMapContext()
    const {pkid, prjId, prjNo} = node.data
    const {prjId: mPrjId} = map.data

    if (! (
        pkid &&
        prjId &&
        mPrjId &&
        prjNo &&
        prjId !== mPrjId
    )) {
        return null
    }

    return (
        <NodeExtraText
            css={cssText}
            node={node}
            text={` @${prjNo}`}
            {...props}
        />
    )
}
