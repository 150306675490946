import extendNode from '../extendNode.mjs'
import useCommonNode from '../_COMMON/useCommonNode.jsx'
import meta from './metaCommentNode.mjs'

/**
 * 注释节点类型的基类
 *
 * 注释节点仅用于分类/说明，不改变父子的业务关系
 */
export default () => {
    const CommonNode = useCommonNode()

    return extendNode(CommonNode, {
        ...meta,

        canDeleteNode(map, node) {
            // 注释节点不改变父子的业务关系，在可编辑子树的情况下可删除自身
            return this.canWriteTree(map, node)
        },

        canLinkTree(map, node, tree) {
            const p = node.parent

            // 如果有父节点，交由父节点判断
            if (p) {
                const {bizNodeType} = p.data
                return map.BizNode[bizNodeType].canLinkTree(map, p, tree)
            }
            // 没有父节点则允许链接任意树
            else {
                return true
            }
        },

        canLinkTreeTo(map, node, tree) {
            const {bizNodeType} = node.data
            const bn = map.BizNode[bizNodeType]

            // 只有全部子树都能链接，才视为可链接
            if (tree.children) {
                return tree.children.every(
                    subTree => bn.canLinkTree(map, node, subTree)
                )
            }
            else {
                return [...map.children(node)].every(
                    subTree => bn.canLinkTree(map, node, subTree)
                )
            }
        },

        canLinkType(map, node, type) {
            const p = node.parent

            // 如果有父节点，交由父节点判断
            if (p) {
                const {bizNodeType} = p.data
                return map.BizNode[bizNodeType].canLinkType(map, p, type)
            }
            // 没有父节点则允许链接任意类型
            else {
                return true
            }
        },

        canMountTree(map, node, tree) {
            const p = node.parent

            // 如果有父节点，交由父节点判断
            if (p) {
                const {bizNodeType} = p.data
                return map.BizNode[bizNodeType].canMountTree(map, p, tree)
            }
            // 没有父节点则允许挂载任意类型
            else {
                return true
            }
        },

        canMountTreeTo(map, node, tree) {
            const {bizNodeType} = node.data
            const bn = map.BizNode[bizNodeType]

            // 只有全部子树都能挂载，才视为可挂载
            if (tree.children) {
                return tree.children.every(
                    subTree => bn.canMountTree(map, node, subTree)
                )
            }
            else {
                return [...map.children(node)].every(
                    subTree => bn.canMountTree(map, node, subTree)
                )
            }
        },

        canMountType(map, node, type) {
            const p = node.parent

            // 如果有父节点，交由父节点判断
            if (p) {
                const {bizNodeType} = p.data
                return map.BizNode[bizNodeType].canMountType(map, p, type)
            }
            // 没有父节点则允许挂载任意类型
            else {
                return true
            }
        },

        defaultChildType(map, node) {
            const _n = map.nodeProxy(node.parent)

            // 如果有父节点，交由父节点处理
            if (_n) {
                return _n.defaultChildType()
            }
            // 没有父节点则没有默认子节点
            else {
                return ''
            }
        },

        isComment: true,

        menuItemsInsertProduct(map, node) {
            const p = node.parent

            if (p) {
                const {bizNodeType} = p.data
                return map.BizNode[bizNodeType].menuItemsInsertProduct(map, p)
            }
            else {
                return CommonNode.menuItemsInsertProduct.call(this, map, node)
            }
        },

        onAttached(map, node, child) {
            if (! node.parent) {
                return
            }

            const {bizNodeType} = node.parent.data
            map.BizNode[bizNodeType].onAttached(map, node.parent, child)
        },
    })
}
