import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaSsins.mjs'
import Model from './ModelSsins.mjs'
import PropertiesPane from './PropertiesPaneSsins.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(SSINS_(DEPENDENCE_ON|DEPLOYED_IN))$/.test(type)
        },

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    borderColor={Color.DARK_GREEN}
                    letters="S"
                    textColor="#000"
                />
            ]
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.DARK_GREEN,
            })
        },

        getTextPrefix(map, node) {
            const defaultPrefix = ComponentNode.getTextPrefix.call(
                this, map, node
            )

            const {ssInsEnv} = node.data

            if (ssInsEnv) {
                return `${defaultPrefix}【${ssInsEnv}】`
            }
            else {
                return defaultPrefix
            }
        },

        async onCreate(map, node) {
            const childTypes = [
                'SSINS_DEPLOYED_IN',
                'SSINS_DEPENDENCE_ON',
            ]

            for (const t of childTypes) {
                const n = map.createNode(t)
                map.appendChild(node, n)
            }

            await ComponentNode.onCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            if ('SS' !== type) {
                return
            }

            const pushData = this._getPushData(map, node, {
                archSsInsDeploys: [],
                archSsInsDependences: [],
            })

            const tree = this.exportTree(map, node, {excludeGrown: true})

            data.archSsInss.push({
                ...pushData,
                map: JSON.stringify(tree.children)
            })
        },
    })
}
