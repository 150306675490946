import useMenuItemsInsertNode from '../../menuItems/useMenuItemsInsertNode.jsx'
import MenuButton from '../MenuButton.jsx'

export default function MenuButtonInsertNode({type, ...props}) {
    const items = useMenuItemsInsertNode(type)

    if (0 === items.length) {
        return null
    }

    const typeText = {
        common: '通用',
        concept: '模件定义',
    }[type]

    return (
        <MenuButton
            items={items}
            {...props}
        >
            {`插入${typeText}节点`}
        </MenuButton>
    )
}
