import {useEffect, useRef} from 'react'
import {Col, Form, Input, Radio, Row} from 'antd'
import Select from '@/components/Form/Select.jsx'
import useSWR from 'swr/immutable'
import http from '@/biz/http.mjs'
import Modal from '@/components/Modal/Modal.jsx'
import runAsync from '@/script/runAsync.mjs'
import SelectBdSvr from './SelectBdSvr.jsx'

const RowInputAlgName = ({form, ...props}) => {
    const bdLogicMode = Form.useWatch('bdLogicMode', form)

    if (0 !== bdLogicMode) {
        return null
    }

    return (
        <Row {...props}>
            <Col span={24}>
                <Form.Item
                    label="业务事务名称"
                    name="algName"
                    rules={[{required: true}]}
                >
                    <Input />
                </Form.Item>
            </Col>
        </Row>
    )
}

const RowInputResume = ({form, ...props}) => {
    const bdLogicMode = Form.useWatch('bdLogicMode', form)

    if (0 !== bdLogicMode) {
        return null
    }

    return (
        <Row {...props}>
            <Col span={24}>
                <Form.Item
                    label="业务事务简述"
                    name="resume"
                >
                    <Input.TextArea />
                </Form.Item>
            </Col>
        </Row>
    )
}

const RowSelectAlg = ({defaultValue, form, ...props}) => {
    const bdLogicMode = Form.useWatch('bdLogicMode', form)
    const bdsvr = Form.useWatch('bdsvr', form)

    const {data: bdlogics} = useSWR(
        1 === bdLogicMode && bdsvr && `/dmbds/rules/${bdsvr.pkid}`,

        () => {
            const {prjId, sVer, bdCode, svrCode} = bdsvr

            return runAsync(
                async () => http.get(
                    '/dmbds/rules',

                    {
                        bdCode,
                        prjId,
                        ruleType: 'BT',
                        sVer,
                        svrCode,
                    },
                ),

                {action: '读取业务事务'}
            )
        }
    )

    useEffect(
        () => {
            if (! bdlogics) {
                return
            }

            const ruleId = (() => {
                for (const {pkid} of bdlogics) {
                    if (pkid === defaultValue) {
                        return pkid
                    }
                }

                return void 0
            })()

            form.setFieldsValue({ruleId})
        },

        [bdlogics]
    )

    if (1 !== bdLogicMode) {
        return null
    }

    const options = bdlogics?.map(
        e => [e.pkid, e.ruleTitle]
    )

    return (
        <Row {...props}>
            <Col span={24}>
                <Form.Item
                    label="业务事务"
                    name="ruleId"
                    rules={[{required: true}]}
                >
                    <Select options={options} />
                </Form.Item>
            </Col>
        </Row>
    )
}

export default function ModalMakeSf({defaultName = '', defaultRuleId, onOk, ...props}) {
    const refLastBdSvr = useRef()
    const [form] = Form.useForm()

    const initialValues = {
        algName: defaultName,
        bdLogicMode: 0,
        sfName: defaultName,
    }

    const handleValuesChange = (changedValues, allValues) => {
        const {bdsvr} = changedValues

        if (! bdsvr) {
            return
        }

        const {bdName, bdNo, svrName, svrNo} = bdsvr

        const newBdSvr = {
            code: bdNo ?? svrNo,
            name: bdName ?? svrName,
        }

        const lastBdSvr = refLastBdSvr.current

        if (lastBdSvr) {
            const replace = (s, oldValue, newValue) => {
                const re = new RegExp(`${oldValue}$`)
                return s.replace(re, newValue)
            }

            const {algName, sfName, sfNo} = allValues

            form.setFieldsValue({
                algName: replace(algName, lastBdSvr.name, newBdSvr.name),
                sfName: replace(sfName, lastBdSvr.name, newBdSvr.name),
                sfNo: replace(sfNo, lastBdSvr.code, newBdSvr.code),
            })
        }
        else {
            form.setFieldsValue({
                algName: `${defaultName}${newBdSvr.name}`,
                sfName: `${defaultName}${newBdSvr.name}`,
                sfNo: newBdSvr.code,
            })
        }

        refLastBdSvr.current = newBdSvr
    }

    const handleFinish = values => {
        const {
            bdLogicMode: _1,
            bdsvr: {bdNo, pkid, svrNo},
            ...data
        } = values

        if (bdNo) {
            onOk({...data, bdId: pkid})
        }
        else if (svrNo) {
            onOk({...data, svrId: pkid})
        }
        else {
            onOk()
        }
    }

    const handleOk = () => {
        form.submit()
    }

    return (
        <Modal
            title="生成交互功能"
            width={600}
            {...props}
            onOk={handleOk}
        >
            <Form
                form={form}
                initialValues={initialValues}
                layout="vertical"
                onValuesChange={handleValuesChange}
                onFinish={handleFinish}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="交互功能名称"
                            name="sfName"
                            rules={[{required: true}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="交互功能代码"
                            name="sfNo"
                            rules={[{required: true}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item
                            label=""
                            name="bdLogicMode"
                            rules={[{required: true}]}
                        >
                            <Radio.Group>
                                <Radio value={0}>同步生成业务事务</Radio>
                                <Radio value={1}>实现已有业务事务</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="业务对象/服务"
                            name="bdsvr"
                            rules={[{required: true}]}
                        >
                            <SelectBdSvr />
                        </Form.Item>
                    </Col>
                </Row>

                <RowInputAlgName form={form} />
                <RowInputResume form={form} />

                <RowSelectAlg
                    defaultValue={defaultRuleId}
                    form={form}
                />
            </Form>
        </Modal>
    )
}
