import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'
import SelectDm from '../DM/SelectDm.jsx'

const FormItemDms = (props) => {
    return (
        <Form.Item
            label="库表域"
            name="dms"
            {...props}
        >
            <SelectDm multiple />
        </Form.Item>
    )
}

const FormItemDtCode = (props) => {
    return (
        <Form.Item
            label="表代码"
            name="dtUserCode"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemDtName = (props) => {
    return (
        <Form.Item
            label="表名称"
            name="dtName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemPrjNo = (props) => {
    return (
        <Form.Item
            label="项目编号"
            name="prjNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const QueryFormSelectDt = (props) => {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemPrjNo />
            </Col>

            <Col span={6}>
                <FormItemDms />
            </Col>

            <Col span={6}>
                <FormItemDtCode />
            </Col>

            <Col span={6}>
                <FormItemDtName />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}

export default QueryFormSelectDt
