import {BatchPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'
import TagSelect from '../../SidePanel/RightPanel/PropertiesControllerTagSelect.jsx'
import Model from './ModelSvrrule.mjs'
import RuleType from '../BDRULE/RuleType.jsx'

const PropertiesPaneBatchSvrrule = (props) => (
    <BatchPropertiesPane
        controllers={
            <>
                <Select
                    options={RuleType}
                    prop="ruleType"
                />

                <TagSelect prop="ruleKey" />
            </>
        }

        Model={Model}
        {...props}
    />
)

export default PropertiesPaneBatchSvrrule
