import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import ButtonJumpStruct from '../_ARCH/PropertiesPaneButtonJumpStruct.jsx'
import ButtonExportModel from '../components/PropertiesPaneButtonExportModel.jsx'
import ButtonImportModules from '../components/PropertiesPaneButtonImportModules.jsx'

const PropertiesPaneFa = (props) => (
    <ProductPropertiesPane
        buttons={
            <>
                <ButtonExportModel
                    global
                    type="fm"
                />

                <ButtonExportModel
                    format="excel"
                    global
                    type="fm"
                />

                <ButtonImportModules type="fm" />
                <ButtonJumpStruct />
            </>
        }

        {...props}
    />
)

export default PropertiesPaneFa
