import {Button} from 'antd'
import buildUrl from '@/script/buildUrl.mjs'
import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

export default function PropertiesPaneButtonViewStruct(props) {
    const [{bizNodeType, prjId, sVer}] = useProperties()

    const [text, baseUrl] = {
        BA: ['业务', 'BaList'],
        FA: ['功能', 'FaList'],
        UA: ['界面', 'UaList'],
    }[bizNodeType]

    const handleClick = () => {
        const url = buildUrl(baseUrl, {prjId, sVer})
        window.open(url)
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >
            编辑{text}结构
        </Button>
    )
}
