import Table from '@/components/Table/Table.jsx'
import TableSelectDesignItems from '../../components/TableSelectDesignItems.jsx'

const TableSelectBd = ({fetch, fetchArgs, query, ...props}) => {
    const columns = [
        {
            title: '业务域',
            dataIndex: 'bmNamePath',
            component: <Table.ViewText />,
        },

        {
            title: '子系统',
            dataIndex: 'ssName',
            component: <Table.ViewText />,
        },

        {
            title: '业务对象/服务名称',
            dataIndex: 'bdName',
            component: <Table.ViewText />,
            getValue: ({bdName, svrName}) => bdName ?? svrName
        },

        {
            title: '业务对象/服务代码',
            dataIndex: 'bdNo',
            component: <Table.ViewText />,
            getValue: ({bdNo, svrNo}) => bdNo ?? svrNo
        },

        {
            title: '业务对象类型',
            dataIndex: 'bdTypeName',
            component: <Table.ViewText />,
            getValue: ({bdTypeName, svrTypeName}) => bdTypeName ?? svrTypeName
        },
    ]

    return (
        <TableSelectDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/bdsvrs"
            query={query}
            {...props}
        />
    )
}

export default TableSelectBd
