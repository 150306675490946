import {Button} from 'antd'
import {join} from 'hopedove-dom/url'
import http from '@/biz/http.mjs'
import useAlert from '@/biz/useAlert.jsx'
import runAsync from '@/script/runAsync.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'

export default function PropertiesPaneButtonExportModel({
    format = '',
    global = false,
    type,
    ...props
}) {
    const alert = useAlert()
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()

    if (1 !== selectedNodes.size) {
        return null
    }

    const name = (() => {
        const n = {
            bm: '业务',
            fm: '功能',
        }[type]

        return {
            '': `${n}模型`,
            'excel': `${n}清单Excel版`
        }[format]
    })()

    const handleClick = async () => {
        const url = {
            bm: join('/prd/export/sa/bm', format),
            fm: join('/prd/export/sa/sf', format),
        }[type]

        const [node] = [...selectedNodes]
        const codeProp = `${type}Code`
        const idProp = `${type}Id`

        const {
            [codeProp]: code,
            pkid,
            prjId,
            sVer,
        } = node.data

        const args = {prjId, sVer}

        if (! global) {
            Object.assign(args, {
                [codeProp]: code,
                [idProp]: pkid,
            })
        }

        await runAsync(
            () => http.post(url, args),
            {action: `导出${name}`}
        )

        alert.generatingFile()
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >
            导出{name}
        </Button>
    )
}
