import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    map.usePermission()
    const selectedNodes = map.useSelectedNodes()
    const nodesCanInsert = map.commands.canInsertProduct(selectedNodes)

    if (0 === nodesCanInsert.length) {
        return []
    }

    const menuItems = nodesCanInsert.map(node => {
        const {bizNodeType} = node.data
        return map.BizNode[bizNodeType].menuItemsInsertProduct(map, node)
    })

    const itemTypes = menuItems.map(
        items => new Set(
            items.map(([type]) => type)
        )
    )

    let commonTypes = new Set(itemTypes[0])

    for (let i = 1; i < menuItems.length; i += 1) {
        commonTypes = commonTypes.intersection(itemTypes[i])
    }

    return menuItems[0]
        .filter(
            ([type]) => (
                map.BizNode[type].readList &&
                commonTypes.has(type)
            )
        )
        .map(
            ([type, label = map.BizNode[type].name]) => ({
                key: type,
                label,

                onClick: () => {
                    map.BizNode[type].onClickMenuItemInsertProduct(
                        map, nodesCanInsert
                    )
                },
            })
        )
}
