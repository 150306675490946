import extendNode from '../extendNode.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaFc.mjs'
import Model from './ModelFc.mjs'
import PropertiesPane from './PropertiesPaneFc.jsx'
import QueryForm from './QueryFormSelectFc.jsx'
import Table from './TableSelectFc.jsx'
import api from './apiFc.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(FC_(DS|JOB|PARAM))$/.test(type)
        },

        async chooseProduct(map, node) {
            const getQuery = (map, node, {
                fms = [],
                ss = {},
                vms = [],
                ...query
            }) => ({
                ...query,
                fmIds: fms.map(({fmId}) => fmId),
                ssId: ss.ssId,
                vmIds: vms.map(({vmId}) => vmId),
            })

            return this._chooseProduct(
                map, node, QueryForm, Table, {getQuery}
            )
        },

        clean(nodeData) {
            const {jobMap, ...data} = ProductNode.clean.call(this, nodeData)
            return data
        },

        getIcons(map, node) {
            if (node.data.pkid) {
                return []
            }
            else {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.GREEN_YELLOW}
                        letters="F"
                        textColor="#666"
                    />
                ]
            }
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {
                fmFjList: [],
                repList: [],
                reqList: [],
            })
        },

        getStyle(map, node) {
            const {isApi} = node.data

            const shape = '1' === isApi ?
                'HorizontalHexagon': 'EllipseRectangle'

            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.GREEN_YELLOW,
                }),

                shape,
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['repList', 'reqList'])
        },

        async onAttachTo(map, node) {
            await ProductNode.onAttachTo.call(this, map, node)
            await this.onInsert(map, node)
        },

        async onCreate(map, node) {
            if (
                void 0 === node.data.isApi &&
                void 0 === node.data.isSi
            ) {
                node.data = {
                    ...node.data,
                    isApi: '1',
                    isSi: '0',
                }
            }

            for (const n of map.walkUp(node.parent)) {
                const {
                    bizNodeType,
                    ssCode,
                    ssId,
                    ssName,
                    vmCode,
                    vmId,
                    vmName,
                } = n.data

                if ('CAT_ROOT_VM' === bizNodeType) {
                    node.data = {
                        ...node.data,
                        ssCode,
                        ssId,
                        ssName,
                        vmCode,
                        vmId,
                        vmName,
                    }

                    break
                }
            }

            await ProductNode.onCreate.call(this, map, node)
        },

        async onPull(map, node) {
            await ProductNode.onPull.call(this, map, node)
            const {jobMap, ...data} = node.data

            if (! jobMap) {
                return
            }

            for (const child of map.children(node)) {
                const {bizNodeType} = child.data

                if ('FC_JOB' === bizNodeType) {
                    const mapData = parseMap(jobMap)
                    const grandchildTrees = mapData.children ?? mapData

                    for (const tree of grandchildTrees) {
                        const grandchild = map.importTree(tree)
                        map.appendChild(child, grandchild)
                    }

                    break
                }
            }

            node.data = data
        },

        async onSetData(map, node, oldData) {
            const {ssCode} = node.data

            if (oldData.ssCode && ssCode !== oldData.ssCode) {
                node.data = {
                    ...node.data,
                    vmCode: '',
                    vmId: '',
                    vmName: ''
                }
            }
        },

        async readMap(args) {
            const desc = Reflect.getOwnPropertyDescriptor(
                ProductNode, 'readMap'
            )

            const readMap = desc.get.call(this)
            const mapData = await readMap.call(this, args)
            delete mapData.root.data.jobMap
            return mapData
        },
    })
}
