import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import meta from './metaBaseNode.mjs'

/**
 * 所有业务节点类型的基类
 */
export default () => {
    return extendNode(null, {
        ...meta,

        /**
         * 节点侧面板
         */
        get nodePanes() {
            const panes = {}
            const {PropertiesPane, PropertiesPaneBatch, StylePane} = this

            if (PropertiesPane) {
                const components = [PropertiesPane]

                if (PropertiesPaneBatch) {
                    components.push(PropertiesPaneBatch)
                }

                panes.properties = {components}
            }

            if (StylePane) {
                panes.style = {components: [StylePane, StylePane]}
            }

            return panes
        },

        /**
         * 当节点被作为子树根删除前回调
         */
        beforeDeleteTree(map, node) {
        },

        /**
         * 能否拷贝树
         */
        canCopyTree(map, node) {
            return true
        },

        /**
         * 能否拷贝样式
         */
        canCopyStyle(map, node) {
            map.logger.error(`${this.name}不支持拷贝样式`, [node])
            return false
        },

        /**
         * 能否删除单个节点
         *
         * 删除单个节点仅删除节点自身，子节点不受影响
         */
        canDeleteNode(map, node) {
            // 对于绝大部分业务节点，删除单个节点都会破坏其父子的从属关系，
            // 故默认不能删除
            const {bizNodeType} = node.data
            const {name} = map.BizNode[bizNodeType]
            map.logger.error(`${name}不支持删除单个节点`, [node])
            return false
        },

        /**
         * 能否删除树
         *
         * 删除树会连同子节点一并删除
         */
        canDeleteTree(map, node) {
            // 对于非根节点，如果其父节点能编辑子树，通常都可以删除
            if (node.parent) {
                const {bizNodeType} = node.parent.data
                return map.BizNode[bizNodeType].canWriteTree(map, node.parent)
            }
            // 对于根节点，则依赖是否有「写」权限。
            // 整个地图的根节点由程序保证不能删除，此处无需处理
            else {
                return map.permission.has('write')
            }
        },

        /**
         * 能否复制树
         *
         * 复制通常会产生一棵完全一样的兄弟树
         */
        canDuplicate(map, node) {
            return (
                this.canDeleteTree(map, node) &&
                // eslint-disable-next-line react/no-is-mounted
                this.isMounted(map, node)
            )
        },

        /**
         * 能否加载
         */
        canGrow(map, node) {
            const {mapTypeCode} = map.data

            if ('ANALYSE_MAP' === mapTypeCode) {
                return false
            }

            return true
        },

        /**
         * 能否在节点后链接树
         *
         * 链接和挂载的区别是，链接仅表示业务上有关联而非从属关系
         */
        canLinkTree(map, node, tree) {
            const {bizNodeType} = tree.data

            // 双向判定
            return (
                // 节点判断能否链接相应类型
                this.canLinkType(map, node, bizNodeType) &&
                // 树判断能否链接到节点上
                map.BizNode[bizNodeType].canLinkTreeTo(map, node, tree)
            )
        },

        /**
         * 能否链接树到节点上
         */
        canLinkTreeTo(map, node, tree) {
            // 通常由节点判断能否链接
            return true
        },

        /**
         * 能否在节点后链接指定类型的节点
         */
        canLinkType(map, node, type) {
            // 默认不能链接任何类型，由子类重写
            return false
        },

        /**
         * 能否在节点后挂载树
         *
         * 挂载和链接的区别是，挂载表示业务上有从属关系
         */
        canMountTree(map, node, tree) {
            const {bizNodeType} = tree.data

            // 双向判定
            return (
                // 节点判断能否挂载相应类型
                this.canMountType(map, node, bizNodeType) &&
                // 树判断能否挂载到节点上
                map.BizNode[bizNodeType].canMountTreeTo(map, node, tree)
            )
        },

        /**
         * 能否挂载树到节点上
         */
        canMountTreeTo(map, node, tree) {
            // 通常由节点判断能否挂载
            return true
        },

        /**
         * 能否在节点后挂载指定类型的节点
         */
        canMountType(map, node, type) {
            // 默认不能挂载任何类型，由子类重写
            return false
        },

        /**
         * 能否移动
         */
        canMove(map, node) {
            return true
        },

        /**
         * 能否卸载
         */
        canShrink(map, node) {
            // 通常能加载的节点都可以卸载
            return this.canGrow(map, node)
        },

        /**
         * 是否可以编辑自身
         */
        canWriteData(map, node) {
            // 对于非根节点，如果其父节点能编辑子树，通常都可以编辑自身
            if (node.parent) {
                const {bizNodeType} = node.parent.data
                return map.BizNode[bizNodeType].canWriteTree(map, node.parent)
            }
            // 对于根节点，则依赖是否有「写」权限
            else {
                return map.permission.has('write')
            }
        },

        /**
         * 能否编辑样式
         */
        canWriteStyle(map, node) {
            return false
        },

        /**
         * 是否可以编辑子树
         */
        canWriteTree(map, node) {
            // 对于非根节点，如果其父节点能编辑子树，通常都可以编辑子树
            if (node.parent) {
                const {bizNodeType} = node.parent.data
                return map.BizNode[bizNodeType].canWriteTree(map, node.parent)
            }
            // 对于根节点，则依赖是否有「写」权限
            else {
                return map.permission.has('write')
            }
        },

        /**
         * 从其他类型转化
         */
        castFrom(map, node) {
            const {bizNodeType} = node.data
            map.BizNode[bizNodeType].castTo(map, node, this.type)
        },

        /**
         * 转化为其他类型
         */
        castTo(map, node, bizNodeType) {
            const {[this.textProp]: text, ...data} = node.data
            const {textProp} = map.BizNode[bizNodeType]

            node.data = {
                // TODO
                //...map.BizNode[bizNodeType].getInitData(map, node.parent),
                ...data,
                bizNodeType,
                [textProp]: text,
            }
        },

        /**
         * 清除冗余数据
         */
        clean(nodeData) {
            const {
                [this.mapProp]: _,
                ...data
            } = nodeData

            return data
        },

        /**
         * 上下文菜单
         */
        contextMenu(map, nodes) {
            return []
        },

        /**
         * 用于创建默认子节点的类型
         */
        defaultChildType(map, node) {
            return ''
        },

        /**
         * 导出节点树
         */
        exportTree(map, node, options) {
            const exportNode = () => {
                return {
                    ...map.exportNode(node),
                    children: [],
                }
            }

            const exportTree = () => {
                const children = [...node.children]
                    .map(n => {
                        const _n = map.nodeProxy(n)
                        return _n.exportTree(options)
                    })
                    .filter(a => a)

                return {
                    ...map.exportNode(node),
                    children,
                }
            }

            if (options?.excludeGrown) {
                if (! node.parent) {
                    return exportTree()
                }
                else if (this.isLinked(map, node)) {
                    return exportNode()
                }
                // eslint-disable-next-line react/no-is-mounted
                else if (this.isMounted(map, node)) {
                    return exportTree()
                }
                else {
                    return null
                }
            }
            else {
                return exportTree()
            }
        },

        /**
         * 默认文本
         */
        defaultText(map, node) {
            // 默认是类型的中文名称
            return this.name
        },

        getDesc(map, node) {
            return ''
        },

        /**
         * 获取图标组
         */
        getIcons(map, node) {
            return []
        },

        /**
         * 获取路径
         */
        getPath(map, node) {
            return [...map.walkUp(node)]
                .reverse()
                .map(n => {
                    const _n = map.nodeProxy(n)
                    const text = _n.getText()
                    const prefix = _n.getTextPrefix()
                    const suffix = _n.getTextSuffix()
                    return [prefix, text, suffix].join('') || '(空白)'
                })
                .join('/')
        },

        /**
         * 获取修订号
         */
        getRev(map, node) {
            return null
        },

        /**
         * 获取节点样式
         */
        getStyle(map, node) {
            const style = {
                backgroundColor: '#fff',
                borderColor: 'transparent',
                borderWidth: 2,
                fontSize: 13,
                fontWeight: 400,
                leadingLineColor: '#666',
                leadingLineWidth: 2,
                lineColor: '#999',
                lineWidth: 2,
                shape: 'RoundedRectangle',
                textAlign: 'left',
                textColor: '#000',
                trailingLineColor: '#666',
                trailingLineWidth: 2,
            }

            if (node === map.root) {
                style.fontSize = 16
                style.fontWeight = 700
            }

            const {
                _compareIdToDiffType,
                _subTreeModifiedCompareIds,
            } = map.data

            if (_compareIdToDiffType && _subTreeModifiedCompareIds) {
                const {mapCompUuid} = node.data
                const diffType = _compareIdToDiffType.get(mapCompUuid)

                if (_subTreeModifiedCompareIds.has(mapCompUuid)) {
                    style.leadingLineColor = Color.DARK_GOLD
                    style.trailingLineColor = Color.DARK_GOLD
                }

                if ('ADD' === diffType) {
                    style.outerBoxBackgroundColor = `${Color.GREEN}50`
                    style.leadingLineColor = Color.GREEN
                }
                else if ('DEL' === diffType) {
                    style.outerBoxBackgroundColor = `${Color.RED}50`
                    style.leadingLineColor = Color.RED
                }
                else if ('UPT' === diffType) {
                    style.outerBoxBackgroundColor = '#ffff00'
                    style.leadingLineColor = Color.DARK_GOLD
                }
            }

            return style
        },

        /**
         * 获取节点文本
         */
        getText(map, node) {
            return this.getTextRaw(map, node)
        },

        /**
         * 获取节点文本前缀
         */
        getTextPrefix(map, node) {
            return ''
        },

        /**
         * 获取节点原始文本
         */
        getTextRaw(map, node) {
            return node.data[this.textProp] ?? ''
        },

        /**
         * 获取节点文本后缀
         */
        getTextSuffix(map, node) {
            return ''
        },

        /**
         * 获取节点提示
         */
        getTitle(map, node) {
            return this.name
        },

        /**
         * 获取节点超链接地址
         */
        async getUrl(map, node, childPath = []) {
            const {[this.textProp]: text = ''} = node.data
            const path = [encodeURIComponent(text), ...childPath]

            if (node.parent) {
                const {bizNodeType} = node.parent.data
                return map.BizNode[bizNodeType].getUrl(map, node.parent, path)
            }
            else {
                return `/${path.join('/')}/`
            }
        },

        /**
         * 获取版本号
         */
        getVersion(map, node) {
            return ''
        },

        /**
         * 加载节点
         */
        async grow(map, node, {depth = 0, maxDepth = 1} = {}) {
            const newDepth = await this._grow(map, node, depth)
            node.isFolded = 0 < depth

            if (newDepth < maxDepth) {
                await Promise.all(
                    [...node.children].map(child => {
                        const n = map.nodeProxy(child)
                        return n.grow({depth: newDepth, maxDepth})
                    })
                )
            }
            else if (1 < maxDepth) {
                map.logger.warn('已达最大加载层数，停止加载', [node])
            }
        },

        /**
         * 是否隐藏
         */
        isHidden(map, node) {
            return false
        },

        /**
         * 是否被链接
         */
        isLinked(map, node) {
            const p = node.parent

            if (! p) {
                return false
            }

            const {bizNodeType} = p.data
            return map.BizNode[bizNodeType].canLinkTree(map, p, node)
        },

        /**
         * 是否被挂载
         */
        isMounted(map, node) {
            const p = node.parent

            if (! p) {
                return false
            }

            const {bizNodeType} = p.data
            return map.BizNode[bizNodeType].canMountTree(map, p, node)
        },

        /**
         * 转化替换节点文本时数据
         */
        mapReplaceTextData(map, node, replace) {
            const text = this.getText(map, node)
            const newText = replace(text)
            return this.mapSetTextData(map, node, newText)
        },

        /**
         * 转化设置节点文本时数据
         */
        mapSetTextData(map, node, text) {
            return {[this.textProp]: text}
        },

        /**
         * 转化更新节点的数据
         */
        mapUpdateNodeData(map, node, data) {
            return data
        },

        /**
         * 该节点类型出现在菜单「插入通用节点」的菜单项，null 表示不出现
         */
        menuInsertCommon(map, nodes) {
            return null
        },

        /**
         * 该节点类型出现在菜单「插入概念节点」的菜单项，null 表示不出现
         */
        menuInsertConcept(map, nodes) {
            return null
        },

        /**
         * 节点的「插入制品节点」的菜单项
         */
        menuItemsInsertProduct(map, node) {
            return []
        },

        /**
         * 当节点通过粘贴被插入子节点时回调
         */
        onAttached(map, node, child) {
        },

        /**
         * 当节点通过粘贴被插入地图时回调
         */
        async onAttachTo(map, node) {
            const _p = map.nodeProxy(node.parent)
            await _p.onAttached(node)

            if (node.isDeleted) {
                return
            }

            // 有可能被父节点改变类型，故需获取新的类型
            const _n = map.nodeProxy(node)

            if (! (
                _n.isLinked() ||
                _n.isMounted()
            )) {
                map.deleteTree(node)
                map.logger.error('子节点类型不合法，不能插入', [node.parent])
            }
            else {
                for (const n of node.children) {
                    const _n = map.nodeProxy(n)
                    await _n.onAttachTo()
                }
            }

            await _n._onImport()
        },

        /**
         * 当节点被创建并插入地图后回调
         */
        async onCreate(map, node) {
            if (void 0 === node.data[this.textProp]) {
                node.data = {
                    ...node.data,
                    [this.textProp]: this.defaultText(map, node),
                }
            }

            await Promise.all(
                [...node.children].map(
                    async n => {
                        const _n = map.nodeProxy(n)
                        await _n.onCreate()
                    }
                )
            )

            await this._onImport(map, node)
        },

        /**
         * 当节点被作为子树根删除后回调
         */
        onDeleteTree(map, node) {
        },

        /**
         * 当用户双击节点时回调
         */
        async onDoubleClick(map, node, event) {
            if (! this.canWriteData(map, node)) {
                event.preventDefault()
            }
        },

        /**
         * 当节点被导入时回调
         */
        async onImport(map, node) {
            await Promise.all(
                [...node.children].map(
                    async n => {
                        const _n = map.nodeProxy(n)
                        await _n.onImport()
                    }
                )
            )

            await this._onImport(map, node)
        },

        /**
         * 节点因重载被插入地图后回调
         */
        async onPull(map, node) {
            await Promise.all(
                [...node.children].map(
                    async n => {
                        const _n = map.nodeProxy(n)
                        await _n.onPull()
                    }
                )
            )
        },

        /**
         * 当祖先节点「更新制品」时回调
         */
        onPush(map, node, type, data) {
            for (const n of map.children(node)) {
                const {bizNodeType} = n.data
                map.BizNode[bizNodeType].onPush(map, n, type, data)
            }
        },

        /**
         * 当节点「更新制品」完成时回调
         */
        onPushDone(map, node) {
        },

        /**
         * 当节点数据更新时回调
         */
        async onSetData(map, node, oldData) {
        },

        /**
         * 替换子树并合并节点属性
         */
        async replace(map, node, tree) {
            const {children, ...props} = tree
            map.deleteChildren(node)

            for (const tree of children) {
                const child = map.importTree(tree)
                map.appendChild(node, child)
            }

            // 设置某些属性的行为可能取决于子节点，故要先处理子节点再设置属性
            Object.assign(node, props)
            await this.onImport(map, node)
        },

        /**
         * 卸载节点
         */
        async shrink(map, node) {
            if (! this.canShrink(map, node)) {
                return
            }

            await Promise.all(
                [...node.children].map(
                    async n => {
                        const _n = map.nodeProxy(n)
                        await _n.shrink()
                    }
                )
            )
        },

        /**
         * 通用图的实际加载逻辑，通常由子类重写。
         * 如果有实际加载，返回的深度应加 1，否则返回原深度。
         */
        async _grow(map, node, depth) {
            return depth
        },

        /**
         * 当节点被导入时回调（非递归）
         */
        async _onImport(map, node) {
        }
    })
}
