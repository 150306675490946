import extendNode from '../extendNode.mjs'
import useFetchDepMapId from '@/biz/useFetchDepMapId.mjs'
import buildUrl from '@/script/buildUrl.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import apiMap from '../../apis/apiMap.mjs'
import Color from '../Color.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaCatDep.mjs'

export default () => {
    const fetchDepMapId = useFetchDepMapId()
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        getStyle(map, node) {
            return {
                ...CategoryNode.getStyle.call(this, map, node),
                backgroundColor: Color.DARK_GREEN,
                textColor: '#fff',
            }
        },

        async shrink(map, node) {
            map.deleteChildren(node)
        },

        onDoubleClick(map, node, event) {
            const {pkid: prjId, prjNo, sVer} = map.data.project
            const url = buildUrl('/ProjectDplMap', {prjId, prjNo, sVer})
            window.open(url)
        },

        async _grow(map, node, depth) {
            const tree = await this._readGrowTree(map, node)

            if (! tree) {
                return depth
            }

            await this.replace(map, node, tree)
            return depth + 1
        },

        async _readGrowTree(map, node) {
            const {prjId, sVer} = map.data
            const pkid = await fetchDepMapId({prjId, sVer})

            if (! pkid) {
                return null
            }

            const {mapModel} = await apiMap.read({pkid})
            const {root: {children}} = parseMap(mapModel)
            return {children}
        },
    })
}
