import extendNode from '../extendNode.mjs'
import buildUrl from '@/script/buildUrl.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import meta from './metaArchNode.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,

        canDeleteTree() {
            return false
        },

        getText(map, node) {
            if ('CAT_ROOT_PRJ' === node.parent?.data.bizNodeType) {
                return this.name
            }
            else {
                return ProductNode.getText.call(this, map, node)
            }
        },

        getDesc(map, node) {
            return ''
        },

        menuInsertConcept() {
            return null
        },

        async onDoubleClick(map, node, event) {
            event.preventDefault()
            const {pkid: prjId, prjNo, sVer} = map.data.project
            const url = buildUrl(this.archUrl, {prjId, prjNo, sVer})
            window.open(url)
        },
    })
}
