import extendNode from '../extendNode.mjs'
import useCommonNode from '../_COMMON/useCommonNode.jsx'
import {NodeStylePane as StylePane} from '../../SidePanel/RightPanel/NodePane.jsx'
import meta from './metaAr.mjs'
import ArType from './ArType.jsx'
import PropertiesPane from './PropertiesPaneAr.jsx'
import PropertiesPaneBatch from './PropertiesPaneBatchAr.jsx'

const ArTypes = new Map(
    ArType.flat().map(([type, name, icon]) => [type, {icon, name, type}])
)

export default () => {
    const CommonNode = useCommonNode()

    return extendNode(CommonNode, {
        ...meta,
        PropertiesPane,
        PropertiesPaneBatch,
        StylePane,

        canDeleteNode(map, node) {
            return this.canWriteTree(map, node)
        },

        canCopyStyle(map, node) {
            return true
        },

        canLinkType(map, node, type) {
            return (
                /^((BD|SVR|UI)(ALG|BASE|EVENT|LOGIC|RULE|SUMMARY)?|BF(DATAFLOW)?|CDT|D[TV]|FC|LSI|SF|U[EV])$/.test(type)
            )
        },

        canMountType(map, node, type) {
            return /^(AR|DOC|PIC|URL)$/.test(type)
        },

        canWriteStyle(map, node) {
            return this.canWriteData(map, node)
        },

        castFrom(map, node) {
            const {bizNodeType} = node.data
            CommonNode.castFrom.call(this, map, node)

            // 尽量匹配类型
            if (ArTypes.has(bizNodeType)) {
                node.data = {...node.data, arTypeCode: bizNodeType}
            }
        },

        getDesc(map, node) {
            const {arTypeCode} = node.data

            if (! arTypeCode || 'GENERAL' === arTypeCode) {
                return this.name
            }

            const {name = ''} = ArTypes.get(arTypeCode) ?? {}
            return name
        },

        defaultChildType(map, node) {
            return this.type
        },

        getIcons(map, node) {
            const {arTypeCode} = node.data
            const {icon} = ArTypes.get(arTypeCode) ?? {}

            if (icon) {
                return [icon]
            }
            else {
                return []
            }
        },

        getStyle(map, node) {
            return {
                ...CommonNode.getStyle.call(this, map, node),
                backgroundColor: '#f8f7f7',
                shape: 'Rectangle',
            }
        },

        getTitle(map, node) {
            const {arTypeCode} = node.data
            const {name} = ArTypes.get(arTypeCode) ?? {}
            return name ?? CommonNode.getTitle.call(this, map, node)
        },

        menuInsertCommon(map, nodes) {
            return this._menuInsertCommon(map, nodes, {rank: 10})
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['BF'],
                ['BFDATAFLOW'],
                ['BD'],
                ['SVR'],
                ['BDSUMMARY_'],
                ['BDRULE_1'],
                ['BDLOGIC_1'],
                ['BDEVENT_1'],
                ['BDALG_'],
                ['SF'],
                ['UI'],
                ['UV'],
                ['UIRULE'],
                ['UE_'],
                ['DT'],
                ['DV'],
                ['CDT'],
                ['LSI'],
                ['FC'],
            ]
        },

        onAttached(map, node, child) {
            if (
                ! this.canLinkTree(map, node, child) &&
                ! this.canMountTree(map, node, child)
            ) {
                this.castFrom(map, child)
            }

            CommonNode.onAttached.call(this, map, node, child)
        },

        async onCreate(map, node) {
            if (
                void 0 === node.data[this.textProp] &&
                void 0 === node.data.arTypeCode
            ) {
                const pd = node.parent.data

                const arTypeCode = (() => {
                    if (
                        'AR' === pd.bizNodeType &&
                        'USER' === pd.arTypeCode
                    ) {
                        return 'EXEC'
                    }
                    else if (/^(BD|SVR)SUMMARY$/.test(pd.bizNodeType)) {
                        return 'USER'
                    }
                    else {
                        return 'GENERAL'
                    }
                })()

                node.data = {
                    ...node.data,
                    [this.textProp]: '',
                    arTypeCode,
                }
            }

            await CommonNode.onCreate.call(this, map, node)
        },
    })
}
