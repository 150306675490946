import {SearchOutlined} from '@ant-design/icons'
import http from '@/biz/http.mjs'
import InputText from '@/components/Form/InputText.jsx'
import {useOpenModal} from '@/components/Modal/Modal.jsx'
import {MapContext, useMapContext} from '@/components/MindMap/index.mjs'
import runAsync from '@/script/runAsync.mjs'
import ModalSelectDesignItems from '../../components/ModalSelectDesignItems.jsx'
import QueryForm from './QueryFormSelectBdSvr.jsx'
import Table from './TableSelectBdSvr.jsx'

export default function SelectBdSvr({value, onChange, ...props}) {
    const openModal = useOpenModal()
    const map = useMapContext()

    const handleClick = async () => {
        const {prjId, sVer} = map.data

        const fetch = query => runAsync(
            async () => {
                const result = await http.get(
                    '/dmbds/bdAndSvr',
                    {prjId, sVer, stsCode: 'RLS', ...query}
                )

                return result.flat()
            },

            {action: '读取业务对象/服务'}
        )

        const getQuery = ({bms = [], ss, ...query}) => ({
            ...query,
            bmIds: bms.map(({bmId}) => bmId),
            ssId: ss?.ssId,
        })

        const items = await openModal(
            <MapContext.Provider value={map}>
                <ModalSelectDesignItems
                    fetch={fetch}
                    getQuery={getQuery}
                    multiple={false}
                    QueryForm={QueryForm}
                    Table={Table}
                    title="选择业务对象/服务"
                    width="80%"
                />
            </MapContext.Provider>
        )

        if (items) {
            onChange(items[0])
        }
    }

    const addonAfter = <SearchOutlined onClick={handleClick} />

    return (
        <InputText
            addonAfter={addonAfter}
            readOnly
            value={value?.bdName ?? value?.svrName}
            onClick={handleClick}
            onKeyDown={e => e.stopPropagation()}
            {...props}
        />
    )
}
