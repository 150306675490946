import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'ruleUserCode',
    mapProp: 'ruleMap',
    name: '处理任务',
    ownerIdProp: 'svrId',
    ownerTextProp: 'svrName',
    ownerType: 'SVR',
    textProp: 'ruleTitle',
    type: 'SVRLOGIC',
}
