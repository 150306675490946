import {useMapContext} from '@/components/MindMap/index.mjs'

export default (type) => {
    const map = useMapContext()
    map.usePermission()
    const selectedNodes = map.useSelectedNodes()

    const hookName = {
        common: 'menuInsertCommon',
        concept: 'menuInsertConcept',
    }[type]

    const nodesCanInsert = map.commands.canInsertChild(selectedNodes)

    if (0 === nodesCanInsert.length) {
        return []
    }

    return Object.values(map.BizNode)
        .map(bn => bn[hookName]?.(map, nodesCanInsert))
        .filter(a => a)
        .sort(([a], [b]) => a - b)
        .map(([, menuItem]) => menuItem)
}
