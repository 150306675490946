export default () => {
    const extensions = () => ({
        createNode(bizNodeType) {
            return this.importTree({data: {bizNodeType}})
        },

        deleteChildren(node) {
            for (const n of node.children) {
                this.deleteTree(n)
            }
        },

        exportNode(node, transform) {
            const {id, isHidden, ...n} = node.export(transform)
            return n
        },

        queryNodes({all, data, isMounted, isLinked, path}) {
            const isRestricted = node => {
                if (! node) {
                    return false
                }

                const _n = this.nodeProxy(node)

                if (isMounted && ! _n.isMounted()) {
                    return false
                }

                if (isLinked && ! _n.isLinked()) {
                    return false
                }

                return true
            }

            const getNodesByPath = () => {
                const nodeTexts = path
                    .slice(1, -1)
                    .split('/')
                    .map(s => decodeURIComponent(s))

                const match = (node, texts) => {
                    const _n = this.nodeProxy(node)
                    const text = _n.getTextRaw()

                    if (text === texts[0]) {
                        if (1 < texts.length) {
                            for (const child of node.children) {
                                const matchedNode = match(child, texts.slice(1))

                                if (matchedNode) {
                                    return matchedNode
                                }
                            }

                            return null
                        }
                        else {
                            return node
                        }
                    }
                    else {
                        return null
                    }
                }

                const node = match(this.root, nodeTexts)

                if (isRestricted(node)) {
                    return [node]
                }
                else {
                    return []
                }
            }

            const getNodesByData = () => {
                const nodes = []
                const entries = Object.entries(data)

                const match = node => entries.every(
                    ([key, value]) => {
                        return (
                            node.data[key] === value &&
                            isRestricted(node)
                        )
                    }
                )

                for (const node of this.walkDown(this.root)) {
                    if (match(node)) {
                        nodes.push(node)

                        if (! all) {
                            break
                        }
                    }
                }

                return nodes
            }

            if (data) {
                return getNodesByData()
            }

            if (path) {
                return getNodesByPath()
            }

            return []
        },

        *walkUpNoComment(node) {
            for (const n of this.walkUp(node)) {
                const {bizNodeType} = n.data
                if (! this.BizNode[bizNodeType].isComment) {
                    yield n
                }
            }
        },
    })

    return {extensions}
}
