import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'
import TagSelect from '../../SidePanel/RightPanel/PropertiesControllerTagSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelSvrrule.mjs'
import RuleType from '../BDRULE/RuleType.jsx'

const PropertiesPaneSvrrule = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="ruleTitle" />
                <Text prop="ruleUserCode" />

                <Select
                    options={RuleType}
                    prop="ruleType"
                />

                <TagSelect prop="ruleKey" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Depend', 'Effect', 'Reference']} />}
        {...props}
    />
)

export default PropertiesPaneSvrrule
