import _Menu from '../../Menu.jsx'
import MenuButtonEdit from '../../menuButtons/MenuButtonEdit.jsx'
import MenuButtonInsertNode from '../../menuButtons/MenuButtonInsertNode.jsx'
import MenuButtonInsertProduct from '../../menuButtons/MenuButtonInsertProduct.jsx'
import MenuButtonInsertProducts from '../../menuButtons/MenuButtonInsertProducts/MenuButtonInsertProducts.jsx'
import MenuButtonSaveMap from './MenuButtonSaveMap.jsx'

export default function Menu(props) {
    return (
        <_Menu {...props}>
            <MenuButtonSaveMap />
            <MenuButtonEdit />
            <MenuButtonInsertNode type="common" />
            <MenuButtonInsertNode type="concept" />
            <MenuButtonInsertProduct />
            <MenuButtonInsertProducts />
        </_Menu>
    )
}
