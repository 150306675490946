import useMenuItemsInsertProduct from '../../menuItems/useMenuItemsInsertProduct.jsx'
import MenuButton from '../MenuButton.jsx'

export default function MenuButtonInsertProduct(props) {
    const items = useMenuItemsInsertProduct()

    if (0 === items.length) {
        return null
    }

    return (
        <MenuButton
            items={items}
            {...props}
        >
            插入模件链接节点
        </MenuButton>
    )
}
