import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import TagSelect from '../../SidePanel/RightPanel/PropertiesControllerTagSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelSvrterm.mjs'

const PropertiesPaneSvrterm = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="termTitle" />
                <Text prop="termUserCode" />
                <TagSelect prop="termKey" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Reference']} />}
        {...props}
    />
)

export default PropertiesPaneSvrterm
