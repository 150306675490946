import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaArCatNode.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canMountType(map, node, type) {
            return 'AR' === type
        },

        defaultChildType(map, node) {
            return 'AR'
        },

        isCatAr: true,

        onAttached(map, node, child) {
            if (
                ! this.canLinkTree(map, node, child) &&
                ! this.canMountTree(map, node, child)
            ) {
                map.BizNode.AR.castFrom(map, child)
            }

            CategoryNode.onAttached.call(this, map, node, child)
        },

        async onPull(map, node) {
            await CategoryNode.onPull.call(this, map, node)
            node.isFolded = true
        },

        _getPushData(map, node) {
            const {bizNodeType} = node.data

            const tree = map.BizNode[bizNodeType].exportTree(
                map, node, {excludeGrown: true}
            )

            const mapJson = JSON.stringify(tree.children)

            return {
                ...tree.data,
                id: node.id,
                [this.mapProp]: mapJson,
            }
        },
    })
}
