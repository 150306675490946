import extendNode from '../extendNode.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import meta from './metaAlgNode.mjs'
import Model from './ModelAlg.mjs'
import PropertiesPane from './PropertiesPaneAlg.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return 'AR' === type
        },

        castFrom(map, node) {
            ComponentNode.castFrom.call(this, map, node)
            const {algType} = this
            node.data = {...node.data, algType}
        },

        onAttached(map, node, child) {
            map.BizNode.AR.onAttached.call(this, map, node, child)
        },

        async onCreate(map, node) {
            if (void 0 === node.data.algType) {
                const {algType} = this
                node.data = {...node.data, algType}
            }

            await ComponentNode.onCreate.call(this, map, node)
        },

        _getStyle(map, node, styleDone) {
            return {
                ...ComponentNode._getStyle.call(this, map, node, styleDone),
                shape: 'EllipseRectangle',
            }
        },

        _getPushData(map, node, slots) {
            const data = ComponentNode._getPushData.call(
                this, map, node, slots
            )

            const {bizNodeType} = node.data

            const tree = map.BizNode[bizNodeType].exportTree(
                map, node, {excludeGrown: true}
            )

            const algMap = JSON.stringify(tree.children)
            return {...data, algMap}
        },
    })
}
