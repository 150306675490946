import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaDt.mjs'
import Model from './ModelDt.mjs'
import PropertiesPane from './PropertiesPaneDt.jsx'
import QueryForm from './QueryFormSelectDt.jsx'
import Table from './TableSelectDt.jsx'
import api from './apiDt.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(CAT|DF)$/.test(type)
        },

        async chooseProduct(map, node) {
            const getQuery = (map, node, {dms = [], ...query}) => {
                const {prjNo} = map.data.project

                const dbCode = (() => {
                    for (const n of map.walkUp(node)) {
                        const {bizNodeType} = n.data

                        if (map.BizNode[bizNodeType].isComment) {
                            continue
                        }
                        else if ('DV_DS' === bizNodeType) {
                            return n.parent.data.dbCode
                        }
                        else {
                            return
                        }
                    }
                })()

                return {
                    prjNo,
                    ...query,
                    dbCode,
                    dmIds: dms.map(({dmId}) => dmId),
                }
            }

            return this._chooseProduct(
                map, node, QueryForm, Table, {getQuery}
            )
        },

        defaultChildType(map, node) {
            return 'DF'
        },

        getIcons(map, node) {
            if (node.data.pkid) {
                return []
            }
            else {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.DARK_CYAN}
                        letters="DT"
                        textColor="#666"
                    />
                ]
            }
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {dmDfList: []})
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.CYAN,
                }),

                shape: 'SingleBreakangle',
            }
        },

        getTextSuffix(map, node) {
            const {dtAlias} = node.data

            if (dtAlias) {
                return ` (${dtAlias})`
            }
            else {
                return ProductNode.getTextSuffix.call(this, map, node)
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['dmDfList'])
        },

        async onSetData(map, node, oldData) {
            const {dbCode} = node.data

            if (oldData.dbCode && dbCode !== oldData.dbCode) {
                node.data = {
                    ...node.data,
                    dmCode: '',
                    dmId: '',
                    dmName: '',
                }
            }
        },

        onAttached(map, node, child) {
            const {bizNodeType} = child.data

            if ('DGF' === bizNodeType) {
                map.BizNode.DF.castFrom(map, child)
            }

            ProductNode.onAttached.call(this, map, node, child)
        },

        async onCreate(map, node) {
            for (const n of map.walkUp(node.parent)) {
                const {
                    bizNodeType,
                    dbCode,
                    dbId,
                    dbName,
                    dmCode,
                    dmId,
                    dmName,
                } = n.data

                if ('CAT_ROOT_DM' === bizNodeType) {
                    node.data = {
                        ...node.data,
                        dbCode,
                        dbId,
                        dbName,
                        dmCode,
                        dmId,
                        dmName,
                    }

                    break
                }
            }

            await ProductNode.onCreate.call(this, map, node)
        },

        async onInsert(map, node) {
            for (const n of map.walkUpNoComment(node.parent)) {
                const {bizNodeType} = n.data

                if ('AR' === bizNodeType) {
                    const {arTypeCode} = n.data

                    if (! /^DB_[CU]$/.test(arTypeCode)) {
                        break
                    }

                    const {pkid} = node.data
                    const {children} = await map.BizNode.DT.readTree({pkid})
                    const ar = map.createNode('AR')
                    ar.data = {...ar.data, arName: '', arTypeCode: 'SET_DF'}
                    map.insertSiblingAfter(node, ar)
                    await map.BizNode.AR.onCreate(map, ar)

                    for (const tree of children) {
                        const {bizNodeType} = tree.data

                        if (/^[BP]K$/.test(bizNodeType)) {
                            continue
                        }

                        const child = map.importTree(tree)
                        map.appendChild(ar, child)
                        await map.BizNode[bizNodeType].onAttachTo(map, child)
                    }
                }

                break
            }

            await ProductNode.onInsert.call(this, map, node)
        },
    })
}
