import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import meta from './metaBdlogic.mjs'
import Model from './ModelBdlogic.mjs'
import RuleType from './RuleType.jsx'
import PropertiesPane from './PropertiesPaneBdlogic.jsx'
import PropertiesPaneBatch from './PropertiesPaneBatchBdlogic.jsx'

const DEFAULT_RESUME = '在此录入简述'

const ruleTypes = new Map(
    RuleType.map(([type, name, icon]) => [type, {icon, name, type}])
)

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,
        PropertiesPaneBatch,

        attrNodes: {
            top: ['ATTR_BDLOGIC_RESUME'],
        },

        canMountType(map, node, type) {
            return /^(AR|DOC|PIC|URL)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'AR'
        },

        getDesc(map, node) {
            const {ruleType} = node.data

            if (! ruleType) {
                return this.name
            }

            const {name = ''} = ruleTypes.get(ruleType) ?? {}
            return name
        },

        getIcons(map, node) {
            const {ruleType} = node.data
            const {icon} = ruleTypes.get(ruleType) ?? {}

            if (icon) {
                return [icon]
            }
            else {
                return []
            }
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.LIGHT_YELLOW,
                shape: 'EllipseRectangle',
            })
        },

        async onCreate(map, node) {
            if (
                void 0 === node.data.ruleType &&
                void 0 === node.data.resume
            ) {
                node.data = {
                    ...node.data,
                    ruleType: 'BT',
                    resume: DEFAULT_RESUME,
                }
            }

            await ComponentNode.onCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            const key = {
                BD: 'ruleList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(map, node)
            data[key].push(pushData)
        },

        async onSetData(map, node, oldData) {
            const {resume, ruleType} = node.data

            if (ruleType !== oldData.ruleType) {
                if (
                    'BL' === ruleType &&
                    resume === DEFAULT_RESUME
                ) {
                    node.data = {...node.data, resume: ''}
                }
                else if (
                    'BT' === ruleType &&
                    resume === ''
                ) {
                    node.data = {...node.data, resume: DEFAULT_RESUME}
                }
            }

            await ComponentNode.onSetData.call(this, map, node, oldData)
        },

        _getPushData(map, node, slots) {
            const data = ComponentNode._getPushData.call(
                this, map, node, slots
            )

            const {bizNodeType} = node.data

            const tree = map.BizNode[bizNodeType].exportTree(
                map, node, {excludeGrown: true}
            )

            const ruleMap = JSON.stringify(tree.children)
            return {...data, ruleMap}
        },

        async _readGrowTree(map, node) {
            return map.BizNode.BDRULE._readGrowTree.call(this, map, node)
        }
    })
}
