import {Col, Form, Input, Row} from 'antd'
import {useSvrType} from '@/biz/bizTable.mjs'
import Select from '@/components/Form/Select.jsx'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'
import SelectBm from '../BM/SelectBm.jsx'
import SelectSs from '../SS/SelectSs.jsx'

const FormItemBms = (props) => {
    return (
        <Form.Item
            label="业务域"
            name="bms"
            {...props}
        >
            <SelectBm multiple />
        </Form.Item>
    )
}

const FormItemSs = (props) => {
    return (
        <Form.Item
            label="子系统"
            name="ss"
            {...props}
        >
            <SelectSs />
        </Form.Item>
    )
}

const FormItemSvrNo = (props) => {
    return (
        <Form.Item
            label="业务服务代码"
            name="svrNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemSvrName = (props) => {
    return (
        <Form.Item
            label="业务服务名称"
            name="svrName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemSvrType = (props) => {
    const {pairs} = useSvrType()

    return (
        <Form.Item
            label="业务服务类型"
            name="svrType"
            {...props}
        >
            <Select
                allowClear
                mapOption={{label: e => e.nameCn}}
                options={pairs}
            />
        </Form.Item>
    )
}

const QueryFormSelectSvr = (props) => {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemBms />
            </Col>

            <Col span={6}>
                <FormItemSs />
            </Col>

            <Col span={6}>
                <FormItemSvrNo />
            </Col>

            <Col span={6}>
                <FormItemSvrName />
            </Col>

            <Col span={6}>
                <FormItemSvrType />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}

export default QueryFormSelectSvr
