import extendNode from '../extendNode.mjs'
import http from '@/biz/http.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import Color from '../Color.mjs'
import useModuleNode from '../_MODULE/useModuleNode.jsx'
import meta from './metaBm.mjs'
import Model from './ModelBm.mjs'
import PropertiesPane from './PropertiesPaneBm.jsx'

export default () => {
    const ModuleNode = useModuleNode()

    return extendNode(ModuleNode, {
        ...meta,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(BM)$/.test(type)
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.LIGHT_YELLOW,
            })
        },

        onAttached(map, node, child) {
            const {bizNodeType} = child.data

            if (/^[DF]M$/.test(bizNodeType)) {
                this.castFrom(map, child)
            }

            ModuleNode.onAttached.call(this, map, node, child)
        },

        onDoubleClick(map, node, event) {
            const {mapTypeCode} = map.data

            if (
                'SA_MAP' !== mapTypeCode ||

                (() => {
                    for (const n of map.walkUpNoComment(node.parent)) {
                        const {bizNodeType} = n.data

                        if (bizNodeType !== this.type) {
                            return 'CAT_DATA' !== bizNodeType
                        }
                    }

                    return true
                })()
            ) {
                ModuleNode.onDoubleClick.call(this, map, node, event)
            }
        },

        onPush(map, node, type, data) {
            const key = {
                BA: 'archBmList',
                BM: 'child'
            }[type]

            if (key) {
                data[key].push(
                    this._getPushData(map, node, {child: []})
                )
            }
        },

        async _readGrowTree(map, node) {
            const {mapTypeCode} = map.data

            if (
                'IS_MAP' === mapTypeCode ||

                (
                    'SA_MAP' === mapTypeCode &&

                    (() => {
                        for (const n of map.walkUpNoComment(node.parent)) {
                            const {bizNodeType} = n.data

                            if (bizNodeType !== this.type) {
                                return 'CAT_DATA' === bizNodeType
                            }
                        }

                        return false
                    })()
                )
            ) {
                const {[this.mapIdProp]: mapId} = node.data
                const {mapModel} = await http.get(`/basmaps/${mapId}/IS`)
                const {root} = parseMap(mapModel)
                return root.children
            }
            else {
                return ModuleNode._readGrowTree.call(this, map, node)
            }
        },
    })
}
