import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import DataType from '../DTF/PropertiesControllerDataType.jsx'
import DataTypeGrpCode from '../DTF/PropertiesControllerDataTypeGrpCode.jsx'
import Model from './ModelParam.mjs'

const PropertiesPaneParam = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="name" />
                <DataTypeGrpCode />
                <DataType />
                <LongText prop="remark" />
            </>
        }

        Model={Model}
        noSn
        snSuffix={<Analyse modes={['Reference']} />}
        {...props}
    />
)

export default PropertiesPaneParam
