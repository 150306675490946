import {useOpenModal} from '@/components/Modal/Modal.jsx'
import {MapContext} from '@/components/MindMap/index.mjs'
import {NodePropModel} from '../../bizNodeModel.mjs'
import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import {ValidationError} from '../Error.mjs'
import useBaseNode from '../_BASE/useBaseNode.jsx'
import ModalSelectDesignItems from '../components/ModalSelectDesignItems.jsx'
import meta from './metaDesignNode.mjs'

/**
 * 设计节点类型的基类
 */
export default () => {
    const openModal = useOpenModal()
    const BaseNode = useBaseNode()

    return extendNode(BaseNode, {
        ...meta,
        api: {},
        attrNodes: null,

        get read() {
            const {read} = this.api

            if (! read) {
                return void 0
            }

            return async args => {
                const {
                    [this.mapProp]: _,
                    ...nodeData
                } = await read(args)

                return {
                    ...nodeData,
                    bizNodeType: this.type,
                }
            }
        },

        get readList() {
            const {readList} = this.api

            if (! readList) {
                return void 0
            }

            return async args => {
                const items = await readList({
                    stsCodes: 'RLS,REVISE',
                    ...args,
                })

                return items.map(
                    ({
                        [this.mapProp]: _,
                        ...nodeData
                    }) => ({
                        ...nodeData,
                        bizNodeType: this.type,
                    })
                )
            }
        },

        canGrow(map, node) {
            if (! BaseNode.canGrow.call(this, map, node)) {
                return false
            }

            if (! node.parent) {
                map.logger.error('根节点不能加载/卸载', [node])
                return false
            }

            if (! node.data.pkid) {
                map.logger.error('概念节点不能加载/卸载', [node])
                return false
            }

            return true
        },

        canLinkTreeTo(map, node, tree) {
            // 概念节点不能链接
            return Boolean(tree.data.pkid)
        },

        /**
         * 能否升级
         */
        canUpgrade(map, node) {
            if (! this.isOutdated(map, node)) {
                map.logger.error('模件没有过时，无需升级', [node])
                return false
            }

            return true
        },

        castTo(map, node, bizNodeType) {
            BaseNode.castTo.call(this, map, node, bizNodeType)

            const {
                pkid: _,
                [this.codeProp]: code,
                ...data
            } = node.data

            const {codeProp} = map.BizNode[bizNodeType]
            node.data = {...data, [codeProp]: code}
        },

        getDesc(map, node) {
            return this.name
        },

        getPushData(map, node) {
            return this._getPushData(map, node)
        },

        getRev(map, node) {
            if (node.parent) {
                return node.data.rev ?? null
            }
            else {
                return null
            }
        },

        getText(map, node) {
            if (this.codeProp && ! node.data.pkid) {
                const parts = []
                const text = node.data[this.textProp]

                if (text) {
                    parts.push(text)
                }

                const code = node.data[this.codeProp]

                if (code) {
                    parts.push(code)
                }

                return parts.join(' #')
            }
            else {
                return BaseNode.getText.call(this, map, node)
            }
        },

        getVersion(map, node) {
            const rev = this.getRev(map, node)
            const {sVer} = node.data
            return (null !== rev && sVer) ? `${sVer}.${rev}` : ''
        },

        /**
         * 是否已不是最新版本
         */
        isOutdated(map, node) {
            const rev = this.getRev(map, node)

            if (null === rev) {
                map.logger.error('模件没有版本化', [node])
                return false
            }

            const {delFlag, lastRev} = node.data

            if ('1' === delFlag) {
                map.logger.error('模件已被删除', [node])
                return false
            }

            if (! lastRev) {
                map.logger.error('模件没有最新版本信息', [node])
                return false
            }

            return rev < lastRev
        },

        mapReplaceTextData(map, node, replace) {
            const data = BaseNode.mapReplaceTextData.call(
                this, map, node, replace
            )

            if (this.codeProp) {
                const {
                    pkid,
                    [this.codeProp]: code = '',
                } = node.data

                if (pkid) {
                    data[this.codeProp] = replace(code)
                }
            }

            return data
        },

        mapSetTextData(map, node, text) {
            if (node.data.pkid || ! this.codeProp) {
                return BaseNode.mapSetTextData.call(this, map, node, text)
            }

            const words = text.split(' ')

            const code = (() => {
                const lastWord = words.at(-1)

                if (lastWord?.startsWith('#')) {
                    return lastWord.replace(/^#/, '')
                }
            })()

            if (code) {
                const text = words.slice(0, -1).join(' ')

                return {
                    [this.codeProp]: code,
                    [this.textProp]: text,
                }
            }
            else {
                return {
                    [this.codeProp]: '',
                    [this.textProp]: text,
                }
            }
        },

        onClickMenuItemInsertProduct(map, nodes) {
            map.commands.insertProduct(nodes, this.type)
        },

        async onInsert(map, node) {
        },

        async onPull(map, node) {
            await BaseNode.onPull.call(this, map, node)
            node.isFolded = Boolean(node.parent)
        },

        async onSetData(map, node, oldData) {
            if (! this.attrNodes) {
                return
            }

            const collectAttrs = (types) => {
                const attrs = {}
                const changedAttrs = []

                for (const type of types) {
                    const {prop} = map.BizNode[type]
                    const isChanged = node.data[prop] !== oldData[prop]

                    const attr = {
                        isChanged,
                        type,
                        value: node.data[prop],
                    }

                    attrs[type] = attr

                    if (isChanged) {
                        changedAttrs.push(attr)
                    }
                }

                return [attrs, changedAttrs]
            }

            const {top = [], bottom = []} = this.attrNodes
            const [topAttrs, changedTopAttrs] = collectAttrs(top)
            const [bottomAttrs, changedBottomAttrs] = collectAttrs(bottom)

            if (
                0 === changedTopAttrs.length &&
                0 === changedBottomAttrs.length
            ) {
                return
            }

            for (const child of map.children(node)) {
                const {bizNodeType} = child.data
                const attr = topAttrs[bizNodeType] ?? bottomAttrs[bizNodeType]

                if (attr) {
                    attr.node = child
                }
            }

            const processAttrNodes = async (changedAttrs, insert) => {
                for (const attr of changedAttrs) {
                    if (attr.value) {
                        const bn = map.BizNode[attr.type]

                        if (attr.node) {
                            attr.node.data = {
                                ...attr.node.data,
                                [bn.textProp]: attr.value,
                            }
                        }
                        else {
                            const n = map.createNode(attr.type)
                            insert(node, n)
                            await bn.onCreate(map, n)
                        }
                    }
                    else {
                        if (attr.node) {
                            map.deleteTree(attr.node)
                        }
                    }
                }
            }

            // TODO 多个属性节点排序
            await Promise.all([
                processAttrNodes(
                    changedTopAttrs,
                    map.prependChild.bind(map)
                ),

                processAttrNodes(
                    changedBottomAttrs,
                    map.appendChild.bind(map)
                ),
            ])
        },

        async shrink(map, node) {
            if (this.canShrink(map, node)) {
                map.deleteChildren(node)
            }
        },

        async onAttachTo(map, node) {
            if (this.isLinked(map, node)) {
                map.deleteChildren(node)
            }

            await BaseNode.onAttachTo.call(this, map, node)
        },

        async _chooseProduct(
            map,
            node,
            QueryForm,
            Table,

            {
                getQuery = (map, node, formValues) => formValues,
                ...modalProps
            } = {},
        ) {
            const fetch = this.readList.bind(this)

            const items = await openModal(
                <MapContext.Provider value={map}>
                    <ModalSelectDesignItems
                        fetch={fetch}
                        getQuery={values => getQuery(map, node, values)}
                        QueryForm={QueryForm}
                        Table={Table}
                        title={`选择${this.name}`}
                        width="80%"
                        {...modalProps}
                    />
                </MapContext.Provider>
            )

            if (items) {
                return items.map(data => ({data}))
            }
            else {
                return []
            }
        },

        _getPushData(map, node, slots) {
            this._validate(node)

            if (slots) {
                BaseNode.onPush.call(this, map, node, this.type, slots)
            }

            const {data, id} = node

            const cat = (() => {
                const path = []

                for (const n of map.walkUp(node.parent)) {
                    const {bizNodeType} = n.data

                    if ('CAT' !== bizNodeType) {
                        break
                    }

                    const text = map.BizNode.CAT.getTextRaw(map, n)
                    path.push(text)
                }

                if (0 < path.length) {
                    return path.reverse().join('@@')
                }
                else {
                    return ''
                }
            })()

            const pushData = {
                ...data,
                ...slots,
                id,
                [this.catProp]: cat,
            }

            return pushData
        },

        _getStyle(map, node, styleDone) {
            const style = BaseNode.getStyle.call(this, map, node)
            const {delFlag, pkid, stsCode} = node.data

            if (pkid) {
                Object.assign(style, styleDone)
            }
            else {
                Object.assign(style, {
                    backgroundColor: Color.GREY,
                    textColor: '#000',
                })
            }

            if (
                '1' === delFlag ||
                'DISCARD' === stsCode
            ) {
                Object.assign(style, {
                    //fontWeight: 'bold',
                    textDecoration: 'line-through red 0.2em',
                })
            }

            return style
        },

        async _initAttrNodes(map, node) {
            if (! this.attrNodes) {
                return
            }

            const {top = [], bottom = []} = this.attrNodes

            const init = async (type, insert) => {
                const n = map.createNode(type)
                insert(node, n)
                await map.BizNode[type].onCreate(map, n)
            }

            await Promise.all([
                Promise.all(
                    top.toReversed().map(
                        async type => init(type, map.prependChild.bind(map))
                    )
                ),

                Promise.all(
                    bottom.map(
                        type => init(type, map.appendChild.bind(map))
                    )
                ),
            ])
        },

        _menuInsertConcept(
            map,
            nodes,

            {
                key = this.type,
                label = this.name,

                onClick = () => {
                    map.commands.insertBizNode(map.selectedNodes, this.type)
                },

                rank = Infinity,
            } = {}
        ) {
            for (const node of nodes) {
                const {bizNodeType} = node.data
                const bn = map.BizNode[bizNodeType]

                if (! bn.canMountType(map, node, this.type)) {
                    return null
                }
            }

            return [rank, {key, label, onClick}]
        },

        async _grow(map, node, depth) {
            if (! this.canGrow(map, node)) {
                return depth
            }

            const tree = await this._readGrowTree(map, node)

            if (! tree) {
                return depth
            }

            const {
                deliverRev,
                deliverVer,
                lastRev,
                rev,
                sVer,
            } = node.data

            if (0 < rev) {
                tree.data = {
                    ...tree.data,
                    deliverRev,
                    deliverVer,
                    lastRev,
                    rev,
                    sVer,
                }
            }

            await this.replace(map, node, tree)
            return depth + 1
        },

        async _growProps(map, node, props) {
            const {definition} = this.Model

            const children = props
                .map(p => NodePropModel.create(definition, p, node.data))
                .filter(m => ! m.isHidden)
                .map(m => {
                    const text = (() => {
                        if ('boolean' === m.type) {
                            if (m.value) {
                                return '是'
                            }
                            else {
                                return '否'
                            }
                        }
                        else if ('key' === m.type) {
                            return m.text
                        }
                        else {
                            if (m.value) {
                                return m.value
                            }
                            else {
                                return '(空)'
                            }
                        }
                    })()

                    return {
                        data: {
                            bizNodeType: 'DPPROP',
                            propName: m.name,
                            text,
                        }
                    }
                })

            const dpProps = map.importTree({
                children,
                data: {bizNodeType: 'DP_PROPS'},
            })

            map.appendChild(node, dpProps)
        },

        async _onImport(map, node) {
            if (
                ! node.parent ||
                // eslint-disable-next-line react/no-is-mounted
                this.isMounted(map, node) ||
                node.firstChild
            ) {
                this._initAttrNodes(map, node)
            }

            await BaseNode._onImport.call(this, map, node)
        },

        async _readGrowTree(map, node) {
            return null
        },

        async _shrinkProps(map, node) {
            for (const n of node.children) {
                if ('DP_PROPS' === n.data.bizNodeType) {
                    map.deleteTree(n)
                }
            }
        },

        _toConcept(map, node) {
            const {dpSn, lastRev, pkid, rev, ...data} = node.data
            node.data = data
        },

        _validate(node) {
            if (this.Model) {
                const model = new this.Model(node.data)
                const errMsg = model.validate()

                if (errMsg) {
                    throw new ValidationError(errMsg, node)
                }
            }
        },
    })
}
