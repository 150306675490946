import extendNode from '../extendNode.mjs'
import useAlgCatNode from '../_ALG_CAT/useAlgCatNode.jsx'
import meta from './metaSvrDevelop.mjs'

export default () => {
    const AlgCatNode = useAlgCatNode()

    return extendNode(AlgCatNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(SVRALG|CAT)$/.test(type)
        },

        isHidden(map, node) {
            const {svrType} = node.parent?.data ?? {}
            return '2' === svrType
        },
    })
}
