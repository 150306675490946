import extendNode from '../extendNode.mjs'
import buildUrl from '@/script/buildUrl.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import apiMap from '../../apis/apiMap.mjs'
import Color from '../Color.mjs'
import IconFlag from '../icons/IconFlag.jsx'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import useDesignNode from '../_DESIGN/useDesignNode.jsx'
import meta from './metaModuleNode.mjs'

export default () => {
    const DesignNode = useDesignNode()
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,

        canDeleteNode(map, node) {
            return this.canWriteTree(map, node)
        },

        canGrow(map, node) {
            if (! DesignNode.canGrow.call(this, map, node)) {
                return false
            }

            if (! node.data[this.mapIdProp]) {
                map.logger.error('未创建地图，不能加载/卸载', [node])
                return false
            }

            return true
        },

        async createMap(map, node) {
            const {prjId, sVer} = map.data

            const {
                pkid,
                [this.codeProp]: code,
                [this.textProp]: mapName,
            } = node.data

            const {
                mapStsCode,
                pkid: mapId,
            } = await apiMap.create({
                mapName,
                mapNo: `${code}_MAP`,
                mapTypeCode: this.mapTypeCode,
                prjId,
                rNodeDataId: pkid,
                sVer,
            })

            return {
                [this.mapIdProp]: mapId,
                [this.mapStsCodeProp]: mapStsCode,
            }
        },

        defaultChildType(map, node) {
            return this.type
        },

        //exportTree(map, node, options) {
            //if (
                //options?.excludeGrown &&
                //this.isLinked(map, node)
            //) {
                //const children = [...node.children]
                    //.filter(n => this.canMountTree(map, node, n))
                    //.map(n => {
                        //const _n = map.nodeProxy(n)
                        //return _n.exportTree(options)
                    //})
                    //.filter(a => a)

                //return {
                    //...map.exportNode(node),
                    //children,
                //}
            //}
            //else {
                //return ComponentNode.exportTree.call(this, map, node, options)
            //}
        //},

        getIcons(map, node) {
            const {
                pkid,
                [this.mapIdProp]: mapId,
                [this.mapStsCodeProp]: mapStsCode,
            } = node.data

            if (pkid) {
                if (mapId) {
                    if ('RLS' === mapStsCode) {
                        return [
                            <IconFlag
                                key="status"
                                color={Color.RED}
                            />
                        ]
                    }
                    else {
                        return [
                            <IconFlag
                                key="status"
                                color={Color.ORANGE}
                            />
                        ]
                    }
                }
                else {
                    return []
                }
            }
            else {
                return [
                    <IconFlag
                        key="status"
                        color={Color.BLUE}
                    />
                ]
            }
        },

        onDoubleClick(map, node, event) {
            const {[this.mapIdProp]: mapId} = node.data

            if (mapId) {
                event.preventDefault()
                this.viewMap(map, node)
            }
        },

        async shrink(map, node) {
            if (! this.canShrink(map, node)) {
                return
            }

            for (const n of [...node.children]) {
                const _n = map.nodeProxy(n)

                if (! _n.isMounted()) {
                    map.deleteTree(n)
                }
            }
        },

        viewMap(map, node) {
            const {[this.mapIdProp]: mapId} = node.data

            if (mapId) {
                const url = buildUrl('/SoftwareMap', {id: mapId})
                window.open(url)
            }
        },

        async _grow(map, node, depth) {
            const _n = map.nodeProxy(node)

            if (! _n.canGrow()) {
                return depth
            }

            const children = await this._readGrowTree(map, node)
            await _n.shrink()

            await Promise.all(
                children.map(async data => {
                    const n = map.importTree(data)
                    map.appendChild(node, n)
                    const _n = map.nodeProxy(n)
                    await _n.onImport()
                })
            )

            return depth + 1
        },

        async _readGrowTree(map, node) {
            const {[this.mapIdProp]: mapId} = node.data
            const mapData = await apiMap.read({pkid: mapId})
            const {root} = parseMap(mapData)
            return root.children
        },

        _toConcept(map, node) {
            ComponentNode._toConcept.call(this, map, node)

            const {
                [this.mapIdProp]: _,
                ...data
            } = node.data

            node.data = data
        },
    })
}
