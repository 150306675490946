import {BatchPropertiesPane} from '../components/NodePropertiesPane.jsx'
import TagSelect from '../../SidePanel/RightPanel/PropertiesControllerTagSelect.jsx'
import Model from './ModelSvrterm.mjs'

const PropertiesPaneBatchSvrterm = (props) => (
    <BatchPropertiesPane
        controllers={
            <>
                <TagSelect prop="termKey" />
            </>
        }

        Model={Model}
        {...props}
    />
)

export default PropertiesPaneBatchSvrterm
