import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import meta from './metaParamNode.mjs'
import Model from './ModelParam.mjs'
import PropertiesPane from './PropertiesPaneParam.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        attrNodes: {
            bottom: ['ATTR_PARAM_REMARK'],
        },

        canLinkType(map, node, type) {
            return /^(DTO)$/.test(type)
        },

        canWriteTree(map, node) {
            return false
        },

        getRev(map, node) {
            return null
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.GREEN_YELLOW,
            })
        },

        getTextPrefix(map, node) {
            const {
                dataTypeCode,
                dataTypeGrpCode,
                dfDtoUserCode,
            } = node.data

            if (dataTypeGrpCode) {
                if (
                    /^(Basic|Other)$/.test(dataTypeGrpCode) &&
                    dataTypeCode
                ) {
                    return `${dataTypeCode} `
                }
                else if (
                    'DTO' === dataTypeGrpCode &&
                    dfDtoUserCode
                ) {
                    return `${dfDtoUserCode} `
                }
                else if (dataTypeCode || dfDtoUserCode) {
                    const type = dataTypeCode || dfDtoUserCode
                    return `${dataTypeGrpCode}<${type}> `
                }
            }

            return ComponentNode.getTextPrefix.call(this, map, node)
        },

        async onPull(map, node) {
            await ComponentNode.onPull.call(this, map, node)
            node.isFolded = false
        },

        async onSetData(map, node, oldData) {
            await ComponentNode.onSetData.call(this, map, node, oldData)
            const {dfDtoId} = node.data

            if (dfDtoId === oldData.dfDtoId) {
                return
            }

            map.deleteChildren(node)

            if (dfDtoId) {
                const data = await map.BizNode.DTO.read({pkid: dfDtoId})
                const dto = map.importTree({data})
                map.appendChild(node, dto)
            }
        },
    })
}
