import {Col, Form, Input, Row} from 'antd'
import {useBdType} from '@/biz/bizTable.mjs'
import Select from '@/components/Form/Select.jsx'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'
import SelectBm from '../BM/SelectBm.jsx'
import SelectSs from '../SS/SelectSs.jsx'

const FormItemBms = (props) => {
    return (
        <Form.Item
            label="业务域"
            name="bms"
            {...props}
        >
            <SelectBm multiple />
        </Form.Item>
    )
}

const FormItemBdNo = (props) => {
    return (
        <Form.Item
            label="业务对象代码"
            name="bdNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemBdName = (props) => {
    return (
        <Form.Item
            label="业务对象名称"
            name="bdName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemBdType = (props) => {
    const {pairs} = useBdType()

    return (
        <Form.Item
            label="业务对象类型"
            name="bdType"
            {...props}
        >
            <Select
                allowClear
                mapOption={{label: e => e.nameCn}}
                options={pairs}
            />
        </Form.Item>
    )
}

const FormItemSs = (props) => {
    return (
        <Form.Item
            label="子系统"
            name="ss"
            {...props}
        >
            <SelectSs />
        </Form.Item>
    )
}

const QueryFormSelectBd = (props) => {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemBms />
            </Col>

            <Col span={6}>
                <FormItemSs />
            </Col>

            <Col span={6}>
                <FormItemBdNo />
            </Col>

            <Col span={6}>
                <FormItemBdName />
            </Col>

            <Col span={6}>
                <FormItemBdType />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}

export default QueryFormSelectBd
