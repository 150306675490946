export async function selectAllNodes() {
    await this.cmd(() => {
        const next = (chain) => {
            const {isFolded} = chain[0]
            const isHidden = this.behaviours.isNodeHidden(chain[0])

            return {
                yieldChildren: ! (isFolded || isHidden),
                yieldNode: ! isHidden,
            }
        }

        const nodes = [...this.walkDown(this.root, {next})]
        this.selectNodes(nodes)
    })
}

export async function selectRoot() {
    await this.cmd(
        () => this.selectNodes([this.root])
    )
}
